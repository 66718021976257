import css from './CompanyDetails.module.sass'

import React, { useState } from 'react'
import PropTypes from 'prop-types'

import Button from '../Button'
import Ionicon from '../Ionicon'
import {
  FormRow,
  FormCol,
  TextField,
  LocationField,
  CustomerCategoryDropdown,
  IndustryDropdown,
  StageDropdown
} from '../Form'
import SectionTitle from '../SectionTitle'
import Text from "../Text";

const CompanyDetails = ({
  name, locations, description, customerCategory, industries, stage,
  onUpdate, onInvalid
}) => {
  const [ collapsed, setCollapsed ] = useState(localStorage.getItem('companyDetailsCollapsed') === 'true')

  const handleCollapseClick = () => {
    setCollapsed(!collapsed)
    localStorage.setItem('companyDetailsCollapsed', !collapsed)
  }

  return (
    <section>
      <div className={collapsed ? css.btnCollapseActive : css.btnCollapse} onClick={handleCollapseClick}>
        <Button variant='icon'>
          <Ionicon name={collapsed ? 'arrowDropdown' : 'arrowDropup'} color='deepBlue' size='24' />
        </Button>
      </div>

      <SectionTitle
        required
        title='Company Info'
        description={"Your company info will display over your pitch video."}
      />

      {!collapsed &&
        <>
          <FormRow>
            <FormCol size='half'>
              <TextField
                label='Company Name *'
                name='name'
                {...name}
                onUpdate={onUpdate}
                onInvalid={onInvalid}
              />
            </FormCol>
          </FormRow>

          <FormRow offset='25'>
            <FormCol>
              <TextField
                label='Tagline *'
                name='description'
                {...description}
                placeholder='Describe your company in one sentence'
                maxlength={75}
                counter
                onUpdate={onUpdate}
                onInvalid={onInvalid}
              />
            </FormCol>
          </FormRow>

          <FormRow offset='25'>
            <FormCol>
              <Text variant='standard'>
                Add pitch search filters so that investors can find your pitch in our private portal and connect
                with you. This info will display over your pitch video.
                Search filters are optional for sharing your pitch.
              </Text>
            </FormCol>
          </FormRow>

          <FormRow offset='25'>
            <FormCol>
              <LocationField
                label='Location *'
                sublabel='Search and select city'
                name='locations'
                {...locations}
                onUpdate={onUpdate}
                onInvalid={onInvalid}
              />
            </FormCol>

            <FormCol>
              <IndustryDropdown
                label='Industry *'
                name='industries'
                {...industries}
                multiple
                maxlength={3}
                onUpdate={onUpdate}
                onInvalid={onInvalid}
              />
            </FormCol>
          </FormRow>

          <FormRow offset='25'>
            <FormCol>
              <CustomerCategoryDropdown
                label='Customer Category *'
                info={
                  'Consumer (or B2C) means that your primary customers are individuals. ' +
                  'Enterprise (or B2B) means that your primary customers are businesses.'
                }
                name='customerCategory'
                {...customerCategory}
                onUpdate={onUpdate}
                onInvalid={onInvalid}
              />
            </FormCol>

            <FormCol>
              <StageDropdown
                label='Stage *'
                info={
                  'Use the following ranges for the amount of funds you’re looking to raise ' +
                  'as general guidance to help choose the stage. Angel (<$1M). ' +
                  'Seed ($1M-$5M). Series A ($5M-$10M). Series B+ (>$10M).'
                }
                name='stage'
                {...stage}
                onUpdate={onUpdate}
                onInvalid={onInvalid}
              />
            </FormCol>
          </FormRow>
        </>
      }
    </section>
  )
}

CompanyDetails.propTypes = {
  name: PropTypes.object,
  locations: PropTypes.object,
  description: PropTypes.object,
  customerCategory: PropTypes.object,
  industries: PropTypes.object,
  stage: PropTypes.object,
  onUpdate: PropTypes.func,
  onInvalid: PropTypes.func
}

export default CompanyDetails
