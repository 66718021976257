import css from './index.module.sass'

import React, { createRef } from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import without from 'lodash.without'
import pick from 'lodash.pick'
import sortedIndexBy from 'lodash.sortedindexby'

import FieldLabel from '../FieldLabel'
import FieldError from '../FieldError'
import Toggle from './Toggle'
import Autocomplete from './Autocomplete'
import OptionsList from './OptionsList'

import { FormContextConsumer } from '../FormContext'
import { MISSING_ERROR } from '../../../constants/errors'
import {
  DOWN_KEY_CODE, UP_KEY_CODE, ENTER_KEY_CODE, SPACE_KEY_CODE, ESC_KEY_CODE
} from '../../../constants/codes'
import { isMobile } from '../../../helpers/devices'
import { getBounds } from '../../../helpers/ui'
import { arrayIncludes } from '../../../helpers/arrays'

const SELECT_ALL_AMOUNT = 3

class Dropdown extends React.PureComponent {
  static propTypes = {
    label: PropTypes.string,
    sublabel: PropTypes.string,
    info: PropTypes.node,
    name: PropTypes.string,
    multiple: PropTypes.bool,
    options: PropTypes.array,
    value: PropTypes.any,
    placeholder: PropTypes.string,
    focusedPlaceholder: PropTypes.string,
    focused: PropTypes.bool,
    maxlength: PropTypes.number,
    emptyIsAll: PropTypes.bool,
    error: PropTypes.oneOfType([ PropTypes.array, PropTypes.string ]),
    loading: PropTypes.bool,
    valueIdKey: PropTypes.string,
    icon: PropTypes.node,
    renderLabel: PropTypes.func,
    onAutocomplete: PropTypes.func,
    onUpdate: PropTypes.func,
    onFetchMore: PropTypes.func,

    required: PropTypes.bool,
    isErrorVisible: PropTypes.bool,
    errorsOverride: PropTypes.object,
    onValid: PropTypes.func,
    onInvalid: PropTypes.func,

    setValidity: PropTypes.func,
    unsetValidity: PropTypes.func
  }

  state = {
    position: null,
    focused: false,
    opened: false,
    hoveredIndex: 0,
    hoveredUsingKeyboard: false,

    error: null,
    isErrorVisible: false
  }

  constructor () {
    super()

    this.portal = document.getElementById('dropdown-portal')

    if (!this.portal) {
      this.portal = document.createElement('div')
      this.portal.id = 'dropdown-portal'
      document.body.appendChild(this.portal)
    }
  }

  containerRef = createRef()
  selectRef = createRef()
  dropdownRef = createRef()
  optionsListRef = createRef()

  componentDidMount () {
    this.props.setValidity(this.props.name, !this.props.error, this.containerRef, this.showError)
    this.validate()

    if (this.props.focused) {
      this.selectRef.current.focus()
    }

    document.addEventListener("scroll", this.positionOptionsList.bind(this))
  }

  componentDidUpdate (prevProps, prevState) {
    const { error, setValidity, name, multiple, emptyIsAll, options, value } = this.props
    const { opened } = this.state

    if (
      (prevProps.error !== error) ||
      (prevState.error !== this.state.error)
    ) {
      setValidity(name, !(error || this.state.error))
    }

    if (prevProps.value !== value) {
      this.validate()

      if (multiple) {
        this.positionOptionsList()
      }
    }

    if (emptyIsAll) {
      if (prevState.opened && !opened && value.length === options.length) {
        this.handleSelectAll()
      }

      if (!prevState.opened && opened && !value.length) {
        this.handleSelectAll()
      }
    }

    if (prevProps.options !== this.props.options && this.state.focused) {
      if (this.props.options && this.props.options.length) {
        this.showOptionsList()
      } else {
        this.hideOptionsList()
      }
    }
  }

  componentWillUnmount () {
    this.props.unsetValidity && this.props.unsetValidity(this.props.name)
    document.removeEventListener('touchstart', this.handleOutsideClick)
    document.removeEventListener("scroll", this.positionOptionsList.bind(this))
  }

  handleOutsideClick = (event) => {
    const { target } = event

    if (
      !this.dropdownRef.current.contains(target) &&
      (!this.optionsListRef.current || !this.optionsListRef.current.contains(target))
    ) {
      this.handleBlur()
      document.removeEventListener('touchstart', this.handleOutsideClick)
    }
  }

  validate () {
    const { name, required, value, multiple, onValid, onInvalid } = this.props

    let error

    if (
      required &&
      (
        (multiple && (!value.length)) ||
        (!multiple && value == null)
      )
    ) {
      error = MISSING_ERROR
    }

    this.setState({ error })

    if (!error) {
      onValid && onValid(name)
    } else {
      onInvalid && onInvalid(name)
    }
  }

  showError = () => {
    this.setState({
      isErrorVisible: true
    })
  }

  isOptionSelected (option) {
    const { value, valueIdKey } = this.props
    return arrayIncludes(value, option.value, valueIdKey)
  }

  getOptionsWithoutOption (option) {
    const { value, valueIdKey } = this.props

    if (valueIdKey) {
      return value.filter(x => x[valueIdKey] !== option.value[valueIdKey])
    }

    return without(value, option.value)
  }

  getValueWithToggledOption (option) {
    const { value, valueIdKey } = this.props

    if (this.isOptionSelected(option)) {
      return this.getOptionsWithoutOption(option)
    } else {
      const index = sortedIndexBy(value, option.value, valueIdKey)
      const nextValue = value.slice(0)
      nextValue.splice(index, 0, option.value)
      return nextValue
    }
  }

  toggleOption = (option) => {
    const { multiple, name, maxlength } = this.props
    let nextValue

    if (!isMobile()) {
      this.selectRef.current.focus()
    }

    if (!multiple) {
      nextValue = option.value
      this.hideOptionsList()
    } else {
      nextValue = this.getValueWithToggledOption(option)
      if (maxlength && nextValue.length > maxlength) {
        return
      }
    }

    this.props.onUpdate({
      [name]: nextValue
    })
  }

  hideOptionsList = () => {
    this.setState({
      opened: false
    })
  }

  positionOptionsList () {
    const offset = getBounds(this.dropdownRef.current)

    this.setState({
      position: {
        top: offset.top + offset.height,
        left: offset.left,
        width: offset.width
      }
    })
  }

  showOptionsList = () => {
    if (!this.props.options || !this.props.options.length) {
      return
    }

    this.setState({
      opened: true
    })

    this.positionOptionsList()

    if (isMobile()) {
      document.addEventListener('touchstart', this.handleOutsideClick)
    }
  }

  toggleOptionsList = () => {
    !this.state.opened ? this.showOptionsList() : this.hideOptionsList()
  }

  isSelectAllEnabled = () => {
    const { multiple, options, maxlength } = this.props

    return multiple && options && options.length > SELECT_ALL_AMOUNT && !maxlength
  }

  getValue (value) {
    const { valueIdKey } = this.props

    return valueIdKey ? value[valueIdKey] : value
  }

  handleKeyDown = (event) => {
    const { options } = this.props
    const { hoveredIndex } = this.state

    if (event.keyCode === DOWN_KEY_CODE) {
      event.preventDefault()

      if (!this.state.opened) {
        this.showOptionsList()
      } else {
        if (hoveredIndex < options.length - 1) {
          this.setState({
            hoveredIndex: this.state.hoveredIndex + 1,
            hoveredUsingKeyboard: true
          })
        }
      }
    } else if (event.keyCode === UP_KEY_CODE) {
      event.preventDefault()

      if (!this.state.opened) {
        this.showOptionsList()
      } else {
        if (hoveredIndex > 0) {
          this.setState({
            hoveredIndex: this.state.hoveredIndex - 1,
            hoveredUsingKeyboard: true
          })
        }
      }
    } else if (event.keyCode === ENTER_KEY_CODE || event.keyCode === SPACE_KEY_CODE) {
      event.preventDefault()

      if (!this.state.opened) {
        this.showOptionsList()
      } else {
        this.toggleOption(options[hoveredIndex])
      }
    } else if (event.keyCode === ESC_KEY_CODE) {
      this.hideOptionsList()
    }
  }

  handleChange = (event) => {
    const { options } = this.props

    this.showOptionsList()

    this.setState({
      hoveredIndex: options.findIndex(o => String(this.getValue(o.value)) === event.target.value),
      hoveredUsingKeyboard: true
    })
  }

  handleFocus = () => {
    this.setState({ focused: true })
  }

  handleBlur = () => {
    if (this.dontBlur) {
      this.dontBlur = false
    } else {
      this.setState({
        focused: false,
        isErrorVisible: true
      })
      this.hideOptionsList()
    }
  }

  handleDropdownClick = () => {
    const { options, onAutocomplete } = this.props

    if (!options || !options.length) {
      return
    }

    if (!this.dontFocus) {
      this.toggleOptionsList()
    }

    this.dontFocus = false

    if (isMobile()) {
      this.handleFocus()
    } else if (!onAutocomplete) {
      this.selectRef.current.focus()
    }
  }

  handleOptionSelect = option => this.toggleOption(option)

  handleOptionHover = (hoveredIndex) => {
    this.setState({
      hoveredIndex,
      hoveredUsingKeyboard: false
    })
  }

  handleOptionsListMouseDown = () => {
    const handleMouseup = () => {
      this.selectRef.current.focus()

      document.removeEventListener('mouseup', handleMouseup)
    }

    document.addEventListener('mouseup', handleMouseup)

    this.dontBlur = true
  }

  handleSelectAll = () => {
    const { value, options } = this.props
    const areAllSelected = value.length === options.length

    this.props.onUpdate({
      [this.props.name]: areAllSelected ? [] : options.map(o => o.value)
    })
  }

  handleClearClick = (shouldFocus) => {
    if (!shouldFocus) {
      this.dontFocus = true
    }

    this.props.onUpdate({
      [this.props.name]: null
    })
  }

  renderAutocomplete () {
    const {
      placeholder, focusedPlaceholder, required, value, loading, icon, renderLabel, onAutocomplete
    } = this.props

    const error = this.props.error || this.state.error
    const isErrorVisible = this.state.isErrorVisible || this.props.isErrorVisible

    return (
      <Autocomplete
        placeholder={placeholder}
        focusedPlaceholder={focusedPlaceholder}
        value={value}
        focused={this.state.focused}
        clearable={!required}
        hasError={error && isErrorVisible}
        loading={loading}
        icon={icon}
        ref={this.dropdownRef}
        renderLabel={renderLabel}
        onClick={this.handleDropdownClick}
        onClear={this.handleClearClick}
        onAutocomplete={onAutocomplete}
        onBlur={isMobile() ? void 0 : this.handleBlur}
        onNavigationKeyDown={this.handleKeyDown}
        onFocus={this.handleFocus}
      />
    )
  }

  render () {
    const {
      options, value, multiple, maxlength, label, sublabel, info, name, errorsOverride, required, valueIdKey,
      renderLabel, onAutocomplete, onFetchMore
    } = this.props
    const { opened, hoveredIndex, hoveredUsingKeyboard, focused } = this.state
    const error = this.props.error || this.state.error
    const isErrorVisible = this.state.isErrorVisible || this.props.isErrorVisible
    const hasOptions = Boolean(options && options.length)

    return (
      <div className={css.container} ref={this.containerRef}>
        {hasOptions &&
          <div className={css.selectWrap}>
            <select
              className={css.select}
              value={
                multiple
                  ? value.map(x => String(this.getValue(x)))
                  : valueIdKey ? (value ? value[valueIdKey] : '') : (value || '')
              }
              multiple={multiple}
              onKeyDown={this.handleKeyDown}
              onChange={this.handleChange}
              onFocus={this.handleFocus}
              onBlur={this.handleBlur}
              ref={this.selectRef}
            >
              {options.map((option) => {
                const optionValue = String(this.getValue(option.value))

                return (
                  <option
                    key={optionValue}
                    value={optionValue}
                  >
                    {option.label}
                  </option>
                )
              })}
            </select>
          </div>
        }

        <FieldLabel
          label={label}
          sublabel={maxlength ? `Select up to ${maxlength}${sublabel ? `, ${sublabel}` : ''}` : sublabel}
          info={info}
          name={name}
        />

        <div className={css.dropdownAndOptionsList}>
          {onAutocomplete
            ? this.renderAutocomplete()
            : (
              <Toggle
                {...pick(this.props, [
                  'options', 'multiple', 'value', 'emptyIsAll', 'placeholder', 'valueIdKey', 'icon'
                ])}
                focused={focused}
                clearable={!multiple && !required}
                hasError={error && isErrorVisible}
                selectAllEnabled={this.isSelectAllEnabled()}
                valueIdKey={valueIdKey}
                renderLabel={renderLabel}
                ref={this.dropdownRef}
                onClick={this.handleDropdownClick}
                onClear={this.handleClearClick}
                onRemove={(option) => {
                  this.dontFocus = true
                  this.toggleOption(option)
                }}
              />
            )
          }

          {opened &&
            ReactDOM.createPortal(
              <OptionsList
                ref={this.optionsListRef}
                showSelectAll={this.isSelectAllEnabled()}
                position={this.state.position}
                options={options}
                hoveredIndex={hoveredIndex}
                selected={value}
                valueIdKey={valueIdKey}
                scrollToHovered={hoveredUsingKeyboard}
                onMouseDown={isMobile() ? void 0 : this.handleOptionsListMouseDown}
                onOptionSelect={this.handleOptionSelect}
                onOptionHover={this.handleOptionHover}
                onSelectAll={this.handleSelectAll}
                onFetchMore={onFetchMore}
              />,
              this.portal
            )
          }
        </div>

        <FieldError error={error} isErrorVisible={!focused && isErrorVisible} overrides={errorsOverride} />
      </div>
    )
  }
}

export default function DropdownContainer (props) {
  return (
    <FormContextConsumer>
      {({ setValidity, unsetValidity }) =>
        <Dropdown {...props} setValidity={setValidity} unsetValidity={unsetValidity} />
      }
    </FormContextConsumer>
  )
}
