import css from './InvestorActions.module.sass'

import React from 'react'
import PropTypes from 'prop-types'
import gql from 'graphql-tag'
import { useMutation } from 'react-apollo'

import Alert from '../Alert'
import Button from '../Button'
import Ionicon from '../Ionicon'
import Text from '../Text'

import { GET_COMPANY_INTERACTION } from '../../constants/queries'
import {
  GTM_INVESTOR_CLICKED_CONNECT_EVENT,
  GTM_INVESTOR_CLICKED_PASS_EVENT,
  GTM_INVESTOR_CLICKED_SAVE_EVENT
} from '../../constants/gtm'
import {
  TOUR_INVESTOR_PASS_CLASS,
  TOUR_INVESTOR_SAVE_CLASS,
  TOUR_INVESTOR_CONNECT_CLASS
} from '../../constants/tour'
import { gtmTrack } from '../../helpers/tracking'
import { formatGraphQLError } from '../../helpers/errors'

const CONNECT = gql`
  mutation connect ($company: String!) {
    connect(company: $company) {
      id
    }
  }
`

const PASS_COMPANY = gql`
  mutation passCompany ($company: String!) {
    passCompany(company: $company) {
      id
    }
  }
`

const SAVE = gql`
  mutation save ($company: String!) {
    save(company: $company) {
      id
    }
  }
`

const updateInteraction = (cache, company, interaction) => {
  cache.writeQuery({
    query: GET_COMPANY_INTERACTION,
    variables: {
      id: company.id
    },
    data: {
      company: {
        ...company,
        interaction
      }
    }
  })
}

const InvestorActions = ({ company, onAction }) => {
  const [ connect, { error: connectError, loading: connecting } ] = useMutation(CONNECT, {
    onCompleted: () => {
      gtmTrack(GTM_INVESTOR_CLICKED_CONNECT_EVENT, {
        pitchtape: { id: company.id }
      })
      onAction && onAction('connected')
    },
    update: cache => updateInteraction(cache, company, 'connected'),
    onError: () => {
      onAction && onAction('connected')
    }
  })

  const [ passCompany, { error: passCompanyError, loading: passing } ] = useMutation(PASS_COMPANY, {
    onCompleted: () => {
      gtmTrack(GTM_INVESTOR_CLICKED_PASS_EVENT, {
        pitchtape: { id: company.id }
      })
      onAction && onAction('passed')
    },
    update: cache => updateInteraction(cache, company, 'passed'),
    onError: () => {
      onAction && onAction('passed')
    }
  })

  const [ save, { error: saveError, loading: saving } ] = useMutation(SAVE, {
    onCompleted: () => {
      gtmTrack(GTM_INVESTOR_CLICKED_SAVE_EVENT, {
        pitchtape: { id: company.id }
      })
      onAction && onAction('saved')
    },
    update: cache => updateInteraction(cache, company, 'saved'),
    onError: () => {
      onAction && onAction('saved')
    }
  })

  const buttonsDisabled = connecting || passing || saving

  const error = connectError || passCompanyError || saveError

  if (error) {
    return <Alert variant='error'>{formatGraphQLError(error)}</Alert>
  }

  const { interaction } = company

  return (
    <>
      {interaction &&
        <div className={css.status}>
          <Text variant='large' weight='500' uppercase>{interaction}</Text>
        </div>
      }

      {!interaction &&
        <Button
          variant='feedAction'
          className={TOUR_INVESTOR_PASS_CLASS}
          disabled={buttonsDisabled || Boolean(interaction)}
          icon={
            <Ionicon name='close' color='white' size='24' />
          }
          onClick={() => passCompany({ variables: { company: company.id } })}
        >
          Pass
        </Button>
      }

      {interaction !== 'connected' && interaction !== 'saved' &&
        <Button
          variant='feedAction'
          className={TOUR_INVESTOR_SAVE_CLASS}
          disabled={buttonsDisabled}
          icon={
            <Ionicon name='bookmark' color='white' size='24' />
          }
          onClick={() => save({ variables: { company: company.id } })}
        >
          Save
        </Button>
      }

      {interaction !== 'connected' &&
        <Button
          variant='feedActionPrimary'
          className={TOUR_INVESTOR_CONNECT_CLASS}
          disabled={buttonsDisabled}
          icon={
            <Ionicon name='checkmark' color='white' size='24' />
          }
          onClick={() => connect({ variables: { company: company.id } })}
        >
          Connect
        </Button>
      }
    </>
  )
}

InvestorActions.propTypes = {
  company: PropTypes.object,
  onAction: PropTypes.func
}

export default InvestorActions
