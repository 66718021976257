import css from './Stars.module.sass'

import React, { useContext } from 'react'
import PropTypes from 'prop-types'

import Ionicon from './Ionicon'
import Text from './Text'

import DevicesContext from './DevicesContext'

const SCALE = [ 1, 2, 3, 4, 5 ]

const Stars = ({ variant, value, color, noDigits }) => {
  const { mobile } = useContext(DevicesContext)

  return (
    <div className={css.container}>
      <div className={css.stars} aria-hidden="true">
        {SCALE.map((scaleItem) => {
          const active = value >= scaleItem

          return (
            <Ionicon
              key={scaleItem}
              name={active ? 'star' : 'starOutline'}
              size={mobile ? '44' : (variant === 'large' ? '36' : '24')}
              color={active ? (color || 'yellow') : 'gray'}
            />
          )
        })}
      </div>

      {value && !noDigits &&
        <Text variant={variant === 'large' ? 'standardLarger' : 'standard'} color='gray' className={css.value}>
          {value.toFixed(1)}
        </Text>
      }
    </div>
  )
}

Stars.propTypes = {
  variant: PropTypes.string,
  value: PropTypes.number,
  color: PropTypes.string,
  noDigits: PropTypes.bool
}

export default Stars
