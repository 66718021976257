import React from 'react'
import PropTypes from 'prop-types'
import { Query } from 'react-apollo'

import InvestorConnections from './InvestorConnections'

import { GET_CONNECTED_COMPANIES, GET_SAVED_COMPANIES, GET_CONNECTED_COMPANY } from '../../constants/queries'

const InvestorConnectionsLinker = (props) =>
  <Query
    query={props.type === 'connected' ? GET_CONNECTED_COMPANIES : GET_SAVED_COMPANIES}
    variables={{ first: 10 }}
    fetchPolicy='network-only'
  >
    {({
      data: companiesData,
      loading: loadingCompanies,
      error: companiesError,
      fetchMore: fetchMoreCompanies,
      networkStatus: companiesNetworkStatus
    }) =>
      <Query
        query={GET_CONNECTED_COMPANY}
        variables={{ id: props.selectedConnectionId }}
        skip={!props.selectedConnectionId}
      >
        {({ data: companyData, loading: loadingCompany, error: companyError }) =>
          <InvestorConnections
            {...props}
            companiesData={companiesData && !loadingCompanies && !companiesError ? companiesData : void 0}
            loadingCompanies={loadingCompanies}
            loadingMoreCompanies={companiesNetworkStatus === 3}
            companiesError={companiesError}
            companyData={companyData && !loadingCompany && !companyError ? companyData : void 0}
            loadingCompany={loadingCompany}
            companyError={companyError}
            onFetchMoreCompanies={fetchMoreCompanies}
          />
        }
      </Query>
    }
  </Query>

InvestorConnectionsLinker.propTypes = {
  type: PropTypes.string,
  selectedConnectionId: PropTypes.string
}

export default InvestorConnectionsLinker
