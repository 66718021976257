export const HOME_PATH = '/'
export const SIGNIN_PATH = '/signin'
export const SIGNUP_PATH = '/signup/:accountType'
export const PASSWORD_PATH = '/password'
export const SIGNOUT_PATH = '/signout'
export const CONFIRM_PATH = '/confirm'
export const STARTUP_PATH = '/startup/:companyId'

// signed in

export const SETTINGS_PATH = '/settings'

// investor

export const SIGNUP_INVESTOR_PROFILE_PATH = '/signup/investor/profile'
export const FEED_PATH = '/feed'
export const ACTIVITY_PATH = '/activity/:type?/:connectionId?'
export const PROFILE_PATH = '/profile'

// founder

export const PITCHTAPE_PATH = '/pitch'
export const CREATE_PATH = '/create'
export const CREATE_VIDEOS_PATH = '/create/videos/:topicId?/:subject?'
export const CREATE_VIDEOS_GENERIC_PATH = '/create/videos'
export const CREATE_VIDEOS_EDIT_PATH = '/create/videos/:topicId/:subject/edit'
export const CREATE_VIDEOS_SLIDE_PATH = '/create/videos/:topicId/:subject/slide'
export const CREATE_VIDEOS_NEW_PATH = '/create/videos/new'
export const CREATE_VIDEOS_NEW_CUSTOM_PATH = '/create/videos/new/custom'
export const CREATE_TELEPROMPTER_SCRIPT = '/create/teleprompter-script'
export const BILLING_PATH = '/billing'
export const ANALYTICS_PATH = '/analytics/:companyId?'
export const SUBSCRIPTION_UPGRADED_PATH = '/subscription/upgraded'
export const SUBSCRIPTION_CANCELED_PATH = '/subscription/canceled'
export const CONNECTIONS_PATH = '/connections/:connectionId?'
export const PRACTICE_PATH = '/practice'
