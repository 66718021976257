import React from 'react'

import Button from './Button'
import Image from './Image'

const Logo = () =>
  <Button variant={null} external href='https://pitchtape.com' block>
    <Image fileName='logo.svg' width='127' alt='Pitchtape' block />
  </Button>

export default Logo
