import css from './PlanDescription.module.sass'

import React from 'react'
import PropTypes from 'prop-types'

import SubscribeButton from '../components/SubscribeButton'
import List from '../components/List'
import Button from '../components/Button'
import Text from '../components/Text'
import { Consumer as CurrentUserConsumer } from '../components/CurrentUserContext'

import { formatPrice } from '../helpers/currencies'
import { isSubscriptionActive } from '../helpers/subscription'
import { PLAN_NAMES, START_PLAN_NAME, CANCELED_STATUS } from '../constants/plans'

const PlanDescription = ({
  plan, name, price, description, prevPlan, features, coupon, buttonProps
}) =>
  <CurrentUserConsumer>
    {({ currentUser: { subscription } }) => {
      const isPlanAllowed = (coupon && coupon.allowedPlans) ? coupon.allowedPlans.includes(plan) : true

      return (
        <article className={css.container}>
          <div className={css.details}>
            <header className={css.header}>
              <div className={css.name}>
                <Text tag='h1' variant='h3' inline weight='500'>
                  {name}
                  {' - '}
                  {price === 0
                    ? 'Free'
                    : price === null
                      ? 'Contact us for pricing'
                      : `${formatPrice(price, { optionalCents: true })}/month`
                  }
                </Text>
              </div>

              {
                (
                  (subscription.plan === plan && isSubscriptionActive(subscription)) ||
                  (plan === START_PLAN_NAME && subscription.status === CANCELED_STATUS)
                ) &&
                  <div className={css.status}>
                    <Text variant='small'>Active</Text>
                  </div>
              }
            </header>

            <Text tag='p' variant='large' offset='single-bottom'>{description}</Text>

            {prevPlan &&
              <Text tag='p' offset='half-bottom'>
                All benefits in the {PLAN_NAMES[prevPlan]} Plan, plus:
              </Text>
            }

            <List variant='bulleted'>
              {features.map((feature, index) =>
                <List.Item key={index}>{feature}</List.Item>
              )}
            </List>
          </div>

          <div className={css.button}>
            {price === null
              ? (
                <Button href='mailto:hello@pitchtape.com' variant='primary'>Contact us</Button>
              )
              : buttonProps && (
                <SubscribeButton
                  plan={plan}
                  description={`${PLAN_NAMES[plan]} plan`}
                  amount={price - (coupon ? coupon.amountOff * 100 : 0)}
                  disabled={!isPlanAllowed}
                  {...buttonProps}
                />
              )
            }
          </div>
        </article>
      )
    }}
  </CurrentUserConsumer>

PlanDescription.propTypes = {
  plan: PropTypes.string,
  name: PropTypes.string,
  price: PropTypes.number,
  description: PropTypes.string,
  prevPlan: PropTypes.string,
  features: PropTypes.array,
  coupon: PropTypes.object,
  buttonProps: PropTypes.object
}

export default PlanDescription
