import React, { useCallback, useContext } from 'react'
import PropTypes from 'prop-types'
import { Redirect } from 'react-router-dom'
import { useQuery, useMutation } from 'react-apollo'
import gql from 'graphql-tag'

import Alert from '../components/Alert'
import Layout from '../components/Layout'
import Loader from '../components/Loader'
import MobileRecordingStub from '../components/MobileRecordingStub'
import VideosRecorder from '../components/VideosRecorder'

import DevicesContext from '../components/DevicesContext'

import { injectParams } from '../helpers/routes'
import { useUploadCompanyVideoSegment } from '../helpers/hooks'
import { formatGraphQLError } from '../helpers/errors'
import { gtmTrack } from '../helpers/tracking'
import { GTM_PITCH_VIDEOS_RECORDED_EVENT } from '../constants/gtm'
import { CREATE_PATH, CREATE_VIDEOS_PATH } from '../constants/routes'
import { GET_USER_COMPANY } from '../constants/queries'
import WelcomeModal from "../components/WelcomeModal";

const STITCH_COMPANY_VIDEOS = gql`
  mutation stitchCompanyVideos {
    stitchCompanyVideos {
      code
      message
    }
  }
`

const CreateVideosScreen = ({ match, history, location }) => {
  const { topicId } = match.params
  const { mobileUa } = useContext(DevicesContext)

  const { data, loading: fetching, error: fetchingError, refetch } = useQuery(GET_USER_COMPANY)

  const company = data?.company
  const topics = company?.videoTopics
  const isFirstTime = location.state?.firstTimeInVideoBuilder === true

  let currentTopicIndex = topics ? topics.findIndex(t => t.id === Number(topicId)) : -1

  if (topicId === 'new') {
    currentTopicIndex = topics?.length || -1
  }

  const getTopicUrl = useCallback((index) => {
    const topic = topics[index]

    if (topic) {
      return injectParams(CREATE_VIDEOS_PATH, { topicId: topic.id, subject: topic.subject })
    }
  }, [ topics ])

  let currentTopic = currentTopicIndex !== -1 ? topics[currentTopicIndex] : null

  const getPreviousTopicUrl = () => {
    if (!currentTopic) {
      return
    }

    return getTopicUrl(currentTopicIndex - 1)
  }

  const getNextTopicUrl = () => {
    if (!currentTopic) {
      return
    }

    return getTopicUrl(currentTopicIndex + 1)
  }

  const [
    uploadCompanyVideoSegment, { error: uploadingError, loading: uploading }
  ] = useUploadCompanyVideoSegment(Number(topicId), {
    onCompleted:  () => {
      if(company?.concatenatedVideo){
        refetch()
      }
    }
  })

  const handleSave = useCallback(({ binary, topic, metadata }) => {
    // console.log("payload",payload)
    return uploadCompanyVideoSegment({
        variables: {
          binary,
          topic,
          duration: `${metadata.duration}`,
          metadata: JSON.stringify(metadata?.metadata || {}),
        },

    })
  }, [ uploadCompanyVideoSegment ])

  const [ stitchCompanyVideos, { loading: stitching } ] = useMutation(STITCH_COMPANY_VIDEOS, {
    onCompleted: () => {
      const firstTime = !company.concatenatedVideo

      if (firstTime) {
        gtmTrack(GTM_PITCH_VIDEOS_RECORDED_EVENT, {
          pitchtape: { id: company.id }
        })
      }

      history.push(CREATE_PATH, {
        initiatedStitch: true,
        initiatedFirstStitch: firstTime
      })
    }
  })

  const handleStitch = useCallback(() => {
    if (!stitching) {
      stitchCompanyVideos()
    }
  }, [ stitching, stitchCompanyVideos ])

  if (mobileUa) {
    return <MobileRecordingStub />
  }

  if (topics?.length && currentTopicIndex === -1) {
    return <Redirect to={
      {
      pathname: getTopicUrl(0),
      state: { firstTimeInVideoBuilder: isFirstTime }
      }
    } />
  }

  return (
    <Layout noFooter noHeader>
      {location.state?.signedUp && <WelcomeModal />}
      {uploadingError &&
      <Alert variant="error">{formatGraphQLError(uploadingError)}</Alert>
      }

      {fetchingError &&
      <Alert variant="error">{formatGraphQLError(fetchingError)}</Alert>
      }

      {fetching
        ? <Loader variant="centered"/>
        : (
          <VideosRecorder
            company={company}
            topics={topics}
            topic={currentTopic}
            path={match.path}
            previousTopicUrl={getPreviousTopicUrl()}
            nextTopicUrl={getNextTopicUrl()}
            saving={uploading}
            onSave={handleSave}
            onStitch={handleStitch}
            refetchCompanyQuery={refetch}
            isFirstTime={isFirstTime}
          />
        )
      }
    </Layout>
  )
}

CreateVideosScreen.propTypes = {
  match: PropTypes.object,
  history: PropTypes.object,
  location: PropTypes.object
}

export default CreateVideosScreen
