import css from './RemoveFromFeedModal.module.sass'
import React from 'react'

import Modal from '../Modal'
import Text from '../Text'
import PropTypes from "prop-types"
const PRODUCT_NAME = 'Pitch Video'


const RemoveFromFeedModal = (props) => {

  return (
    <Modal
      buttons={props.buttons}
      visible={props.visible}
      loading={props.loading}
    >
      <div className={css.confirmModalContainer}>
        <Text tag='p' variant='h3'>
          Are you sure you’d like to remove your pitch video from our investors feed?
        </Text>
        <Text tag='p' variant='standardLarger' className={css.confirmNote}>
          You can re-submit your pitch by clicking on the submit {PRODUCT_NAME.toLocaleLowerCase()} button.
        </Text>
      </div>
    </Modal>
  )
}
RemoveFromFeedModal.propTypes = {
  buttons: PropTypes.node,
  visible: PropTypes.bool,
  loading: PropTypes.bool
}

export default RemoveFromFeedModal
