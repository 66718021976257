import css from './HighlightsField.module.sass'

import React, { useCallback, useState, useEffect, useContext } from 'react'
import PropTypes from 'prop-types'
import { Prompt } from 'react-router-dom'

import Button from '../Button'
import { EditorField } from '../Form'
import Link from '../Link'
import SectionTitle from '../SectionTitle'


import { UNLOAD_TEXT } from '../../constants/forms'
import { BILLING_PATH } from '../../constants/routes'
import CurrentUserContext from "../CurrentUserContext"
import Alert from "../Alert"

const HighlightsField = ({ value: _value, onUpdate, ...rest }) => {
  const [value, setValue] = useState(_value)
  const { currentUser: { subscription } } = useContext(CurrentUserContext)
  const isPaidUserSubscription = subscription ? subscription.isPaidSubscription : false

  useEffect(() => {
    setValue(_value)
  }, [_value])

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      event.preventDefault()
      event.returnValue = UNLOAD_TEXT
    }

    if (value !== _value) {
      window.addEventListener('beforeunload', handleBeforeUnload)

      return () => {
        window.removeEventListener('beforeunload', handleBeforeUnload)
      }
    }
  }, [value, _value])

  const handleSave = useCallback(() => {
    onUpdate({ highlights: value }, 0)
  }, [value, onUpdate])

  const handleUpdate = useCallback(({ highlights }) => {
    setValue(highlights)
  }, [])

  return (
    <section>
      <SectionTitle
        optional
        title='Key Highlights'
        description={
          <>
            Add key highlights to your pitch.
          </>
        }
        warning={!isPaidUserSubscription &&
          <Alert variant='upgrade' centered textColor={'black'} noClose>
            <Link variant={'inherit'} to={BILLING_PATH}  >
              Upgrade to Grow plan to add Key Highlights to your pitch.
            </Link></Alert>
        }
      />

      <Prompt
        when={value !== _value}
        message={UNLOAD_TEXT}
      />

      <EditorField
        readOnly={isPaidUserSubscription === false}
        name='highlights'
        value={value}
        onUpdate={handleUpdate}
        {...rest}
      />

      <div className={css.buttons}>
        <Button
          variant='primary'
          disabled={value === _value || isPaidUserSubscription === false}
          onClick={handleSave}
        >
          Save{value === _value ? 'd' : ''}
        </Button>
      </div>
    </section>
  )
}

HighlightsField.propTypes = {
  value: PropTypes.string,
  onUpdate: PropTypes.func
}

export default HighlightsField
