import css from './MobileRecordingStub.module.sass'

import React, { useContext } from 'react'

import Layout from './Layout'
import Text from './Text'

import CurrentUserContext from './CurrentUserContext'

const MobileRecordingStub = () => {
  const { currentUser: { firstName } } = useContext(CurrentUserContext)

  return (
    <Layout noFooter>
      <article className={css.container}>
        <header className={css.header}>
          <Text tag='h1' variant='h3' color='white'>Welcome, {firstName}!</Text>
        </header>

        <div className={css.body}>
          <Text tag='p' variant='standardLarger' weight='500' offset='single-bottom'>
            We are a video pitch creation platform for startup founders.
          </Text>

          <Text tag='p' variant='standardLarger' offset='single-bottom'>
            Mobile video recording is coming soon!
          </Text>

          <Text tag='p' variant='standardLarger'>
            Please continue on your laptop or desktop computer using the Google Chrome browser,
            where we’ll walk you through the process of creating a self-recorded
            video pitch that you can share or make public in our investor portal.
          </Text>
        </div>
      </article>
    </Layout>
  )
}

export default MobileRecordingStub
