import css from './Interactions.module.sass'

import React from 'react'
import PropTypes from 'prop-types'

import Text from '../Text'
import List from '../List'
import CircleChart from '../CircleChart'

const Interactions = ({ connected, passed, saved }) => {
  const total = connected + passed + saved

  const connectedPercentage = total ? Math.floor(connected / total * 100) : 0
  const passedPercentage = total ? Math.floor(passed / total * 100) : 0
  const savedPercentage = total ? Math.floor(saved / total * 100) : 0

  return (
    <div className={css.container}>
      {!total
        ? (
          <div className={css.empty}>
            No interactions during this period.
          </div>
        )
        : (
          <>
            <div className={css.chart}>
              <CircleChart
                percentages={[ connectedPercentage, savedPercentage, passedPercentage ]}
              />

              <div className={css.interactions}>
                <Text block weight='500'>{total}</Text>
                <Text variant='small'>total</Text>
              </div>
            </div>

            <div className={css.list}>
              <List gap='15'>
                <List.Item>
                  <Text color='green-bg' variant='sectionTitleSmall' className={css.percentage}>
                    {connectedPercentage}%
                  </Text>

                  <Text weight='500'>
                    Connect <Text color='gray'>({connected} total)</Text>
                  </Text>
                </List.Item>

                <List.Item>
                  <Text color='blue-bg' variant='sectionTitleSmall' className={css.percentage}>
                    {savedPercentage}%
                  </Text>

                  <Text weight='500'>
                    Save <Text color='gray'>({saved} total)</Text>
                  </Text>
                </List.Item>

                <List.Item>
                  <Text color='yellow-bg' variant='sectionTitleSmall' className={css.percentage}>
                    {passedPercentage}%
                  </Text>

                  <Text weight='500'>
                    Pass <Text color='gray'>({passed} total)</Text>
                  </Text>
                </List.Item>
              </List>
            </div>
          </>
        )
      }
    </div>
  )
}

Interactions.propTypes = {
  title: PropTypes.string,
  connected: PropTypes.number,
  passed: PropTypes.number,
  saved: PropTypes.number
}

export default Interactions
