import css from './index.module.sass'

import React, { useContext } from 'react'
import PropTypes from 'prop-types'

import Asterisk from '../../Asterisk'
import Text from '../../Text'
import Well from '../../Well'
import Item from './Item'

import {
  DETAILS_SECTION, DECK_SECTION, HIGHLIGHTS_SECTION, TEAM_SECTION, VIDEO_PREVIEW_SECTION
} from '../../../constants/pitchform'
import { areCompanyDetailsComplete } from '../../../helpers/companies'
import { removeHtmlTags } from '../../../helpers/strings'
import CurrentUserContext from "../../CurrentUserContext"

const ProgressBlock = ({ company, onSelect }) => {
  const {currentUser: {subscription}} = useContext(CurrentUserContext)
  const isPaidUserSubscription = subscription ? subscription.isPaidSubscription : false
  const ITEMS = [
    {
      section: VIDEO_PREVIEW_SECTION,
      title: 'Pitch video',
      required: true,
      complete: Boolean(company.concatenatedVideo)
    },
    {
      section: DETAILS_SECTION,
      title: 'Company info',
      required: true,
      complete: areCompanyDetailsComplete(company)
    },
    {
      section: DECK_SECTION,
      title: 'Pitch deck',
      complete: Boolean(company.slidesFile)
    },
    {
      section: TEAM_SECTION,
      title: 'Team info',
      complete: Boolean(company.members?.length)
    },
    {
      section: HIGHLIGHTS_SECTION,
      title: 'Key highlights',
      complete: Boolean(removeHtmlTags(company.highlights))
    }
  ]

  return (
    <Well title='Pitch Overview'>
      <div className={css.container}>
        <Text tag='p'>
          <Asterisk />
          <Text variant='standard' italic>Required to submit your pitch to our private investor portal.
          </Text>
        </Text>

        <ul className={css.list}>
          {ITEMS.map((item, index) =>
            <Item
              key={index}
              index={index}
              {...item}
              onSelect={onSelect}
              isPaidUserSubscription={isPaidUserSubscription}
            />
          )}
        </ul>
      </div>
    </Well>
  )
}

ProgressBlock.propTypes = {
  company: PropTypes.object,
  onAdd: PropTypes.func,
  onSelect: PropTypes.func
}

export default ProgressBlock
