import css from './VideoPreview.module.sass'

import React from 'react'
import PropTypes from 'prop-types'

import VideoNotReady from '../VideoNotReady'
import VideoPlayer from '../VideoPlayer'
import { VIDEO_FINISHED_STATUS } from '../../constants/videos'
import Well from "../Well";

import VideoPreviewHeader from "./VideoPreviewHeader";

const VideoPreview = ({ company }) => {
    const { concatenatedVideo } = company


    return (
      <Well title='Pitch Video Preview'>
        <div className={css.container}>
          {concatenatedVideo?.status === VIDEO_FINISHED_STATUS
              ? (
                  <VideoPlayer
                      header={
                          <VideoPreviewHeader
                              company={company}
                              editable
                          />
                      }
                      topics={company.videoTopics}
                      binary={concatenatedVideo.binary}
                      hlsReady={ false }
                  />
              )
              : (
                  <VideoNotReady company={company} />
              )
          }
        </div>
      </Well>

  )
}

VideoPreview.propTypes = {
  company: PropTypes.object
}

export default VideoPreview
