import React from 'react'

import Link from '../Link'
import List from '../List'
import Well from '../Well'

import { VIDEO_PITCH_TEMPLATE_URL, RECORDING_TIPS_URL } from '../../constants/urls'
import { PRACTICE_PATH } from "../../constants/routes"

const KnowledgeCenterBlock = () => {
  return (
    <Well title='Pitch Resources' highlight highlightTitle={false}>
      <List gap='15'>
        <List.Item>
          <Link variant='inherit' external href={VIDEO_PITCH_TEMPLATE_URL}>
            Startup pitch topics doc
          </Link>
        </List.Item>

        <List.Item>
          <Link variant='inherit' external href={RECORDING_TIPS_URL}>Tips for self-recording a pitch</Link>
        </List.Item>

        <List.Item>
          <Link variant='inherit' to={PRACTICE_PATH}>Practice pitching on video</Link>
        </List.Item>

      </List>
    </Well>
  )
}

export default KnowledgeCenterBlock
