import mapValues from 'lodash.mapvalues'
import omit from 'lodash.omit'

import { addProtocol } from './strings'

export const serverDataToFormData = (_data, keys, keysToOmit = []) => {
  const data = omit(_data, [ 'id', '__typename', ...keysToOmit ])

  return mapValues(data, (v, k) => {
    if (!keys.includes(k)) {
      throw new Error(`Property ${k} should not be there`)
    }

    if (Array.isArray(v)) {
      return v.map(x => typeof x === 'object' ? x.id : x)
    } else if (typeof v === 'object' && v !== null) {
      return v.id
    }

    return v
  })
}

export const formDataToServerData = (data, { urlKeys } = {}) => {
  return mapValues(data, (value, key) => {
    if (urlKeys.includes(key)) {
      return addProtocol(value)
    }

    if (Array.isArray(value)) {
      return value.map(x => typeof x === 'object' ? x.id : x)
    } else if (typeof value === 'object' && value != null) {
      return value.id
    }

    return value
  })
}

const errorForField = (errors, field) => {
  const error = errors && errors.find(e => e.field === field)

  if (error) {
    return {
      error: error.messages[0],
      isErrorVisible: true
    }
  }

  return {}
}

export const formDataWithErrors = (data, errors) => {
  return mapValues(data, (v, k) => ({
    ...v,
    ...errorForField(errors, k)
  }))
}

export const prepareFormData = ( formData, requiredFields = [] ) => {
  if ( requiredFields.length )
    return mapValues( formData, ( v, k ) => ( {
      value: v,
      required: requiredFields ? requiredFields.includes( k ) : false
    } ) )
  else
    return mapValues( formData, ( v ) => ( {
      value: v,
      required: false
    } ) )
}
