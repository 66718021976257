import css from './AvgRating.module.sass'

import React from 'react'
import PropTypes from 'prop-types'

import Loader from '../Loader'
import Stars from '../Stars'
import Text from '../Text'

const AvgRating = ({ loading, avgRating }) => {
  const renderCount = (count, larger) =>
    <Text variant={larger ? 'standardLarger' : 'standard'} color='gray'>
      {count > 0 ? `${count} rating${count === 1 ? '' : 's'}` : 'Not rated'}
    </Text>

  if (loading) {
    return <Loader />
  }

  if (!avgRating) {
    return null
  }

  return (
    <>
      <div className={css.rowOverall}>
        <Text tag='h2' variant='standardLarger' weight='500' offset='half-bottom'>
          Overall Pitch rating
        </Text>
        <div className={css.rowBody}>
          <div className={css.stars}>
            <Stars variant='large' value={avgRating.overall} />
          </div>

          {renderCount(avgRating.overallRatingCount, true)}
        </div>
      </div>

      <div className={css.row}>
        <Text tag='h3' variant='standard' offset='quarter-bottom'>Video pitch</Text>
        <div className={css.rowBody}>
          <div className={css.stars}>
            <Stars value={avgRating.pitchVideo} />
          </div>
          {renderCount(avgRating.pitchVideoRatingCount)}
        </div>
      </div>

      <div className={css.row}>
        <Text tag='h3' variant='standard' offset='quarter-bottom'>Pitch deck</Text>
        <div className={css.rowBody}>
          <div className={css.stars}>
            <Stars value={avgRating.pitchDeck} />
          </div>
          {renderCount(avgRating.pitchDeckRatingCount)}
        </div>
      </div>

      <div className={css.row}>
        <Text tag='h3' variant='standard' offset='quarter-bottom'>Team</Text>
        <div className={css.rowBody}>
          <div className={css.stars}>
            <Stars value={avgRating.teamSection} />
          </div>
          {renderCount(avgRating.highlightsSectionRatingCount)}
        </div>
      </div>

      <div className={css.row}>
        <Text tag='h3' variant='standard' offset='quarter-bottom'>Key highlights</Text>
        <div className={css.rowBody}>
          <div className={css.stars}>
            <Stars value={avgRating.highlightsSection} />
          </div>
          {renderCount(avgRating.teamSectionRatingCount)}
        </div>
      </div>
    </>
  )
}

AvgRating.propTypes = {
  loading: PropTypes.bool,
  avgRating: PropTypes.object
}

export default AvgRating
