import css from './SubmitToFeedModal.module.sass'
import React from 'react'

import Modal from '../Modal'
import Text from '../Text'
import PropTypes from "prop-types";


const SubmitToFeedModal = (props) => {

  return (
    <Modal
      buttons={props.buttons}
      visible={props.visible}
      loading={props.loading}
    >
      <div className={css.confirmModalContainer}>
        <Text tag='p' variant='h3'>
          Ready to submit your pitch video to our investors feed?
        </Text>
        <Text tag='p' variant='standardLarger' className={css.confirmNote}>
          Note: Any future changes made to your pitch will be updated in the feed in real-time.
        </Text>
      </div>
    </Modal>
  )
}
SubmitToFeedModal.propTypes = {
  buttons: PropTypes.node,
  visible: PropTypes.bool,
  loading: PropTypes.bool
}

export default SubmitToFeedModal
