import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import gql from 'graphql-tag'
import { Mutation } from 'react-apollo'

import AsyncScript from './AsyncScript'
import { Consumer as CurrentUserConsumer } from './CurrentUserContext'

import { STRIPE_API_KEY } from '../constants/keys'
import { STRIPE_JS_URL } from '../constants/urls'

const SUBSCRIBE_TO_PAID_PLAN = gql`
  mutation subscribeToPaidPlan ($stripeToken: String!, $subscriptionPlan: String!, $coupon: String) {
    subscribeToPaidPlan(stripeToken: $stripeToken, subscriptionPlan: $subscriptionPlan, coupon: $coupon) {
      code
      message
    }
  }
`

const UPDATE_CUSTOMER_CARD = gql`
  mutation updateCustomerCard ($stripeToken: String!) {
    updateCustomerCard(stripeToken: $stripeToken) {
        code
        message
    }
  }
`

const Checkout = ({ loading, error, children }) => {
  const openedRef = useRef()

  return (
    <CurrentUserConsumer>
      {({ currentUser }) =>
        <Mutation mutation={UPDATE_CUSTOMER_CARD}>
          {(updateCustomerCard, { loading: updating, error: updatingError }) =>
            <Mutation mutation={SUBSCRIBE_TO_PAID_PLAN}>
              {(subscribeToPaidPlan, { loading: subscribing, error: subscribingError }) => {
                const checkout = ({ options, plan, coupon, onSuccess, onError }) => {
                  if (openedRef.current) {
                    return
                  }

                  openedRef.current = true

                  const handler = window.StripeCheckout.configure({
                    key: STRIPE_API_KEY,
                    locale: 'auto',
                    email: currentUser.email,
                    allowRememberMe: false,
                    billingAddress: true,
                    panelLabel: options?.amount > 0 ? 'Pay {{amount}}' : 'Subscribe',
                    token: (token) => {
                      if (plan) {
                        subscribeToPaidPlan({
                          variables: {
                            stripeToken: token.id,
                            subscriptionPlan: plan,
                            coupon
                          }
                        })
                          .then(onSuccess)
                          .catch(onError)
                      } else {
                        updateCustomerCard({
                          variables: {
                            stripeToken: token.id
                          }
                        })
                          .then(onSuccess)
                          .catch(onError)
                      }
                    },
                    closed: () => {
                      openedRef.current = false
                    },
                    ...(!plan && {
                      panelLabel: 'Update Card Details'
                    })
                  })

                  handler.open({
                    name: 'Pitchtape, Inc.',
                    ...options
                  })
                }

                return children({
                  checkout,
                  subscribing,
                  updating,
                  loading,
                  error: error || updatingError || subscribingError
                })
              }}
            </Mutation>
          }
        </Mutation>
      }
    </CurrentUserConsumer>
  )
}

Checkout.propTypes = {
  loading: PropTypes.bool,
  error: PropTypes.object,
  children: PropTypes.func
}

export default function CheckoutAsyncScript (props) {
  return (
    <AsyncScript src={STRIPE_JS_URL}>
      {({ loading, error }) =>
        <Checkout {...props} loading={loading} error={error} />
      }
    </AsyncScript>
  )
}
