import React, { useContext } from 'react'
import SectionTitle from '../SectionTitle'

import DeckManager from '../DeckManager'
import Link from "../Link"
import { BILLING_PATH } from "../../constants/routes"
import Alert from "../Alert"
import CurrentUserContext from "../CurrentUserContext"

const DeckDetails = () => {
  const {currentUser: {subscription}} = useContext(CurrentUserContext)
  const isPaidUserSubscription = subscription ? subscription.isPaidSubscription : false

  return (
    <DeckManager
      header={
        <SectionTitle
          optional
          title='Pitch Deck'
          description={
            <>
              Upload your pitch deck in PDF format. File size is up to 10 MB.
            </>
          }
          warning={!isPaidUserSubscription ?(
            <Alert variant="upgrade" centered textColor={ 'black' } noClose>
              <Link variant={ 'inherit' } to={ BILLING_PATH }>
                Upgrade to Grow plan to add a Pitch Deck to your pitch.
              </Link></Alert>) : ''
          }
        />
      }
      disabled={isPaidUserSubscription===false}
    />
  )
}

export default DeckDetails
