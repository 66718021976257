import React, { useState, useCallback, useContext } from 'react'
import PropTypes from 'prop-types'

import Button from '../Button'
import { RatingField } from '../Form'
import RatingModal from '../RatingModal'
import Stars from '../Stars'

import DevicesContext from '../DevicesContext'

const Rating = ({ company, onRate }) => {
  const [ temporaryRating, setTemporaryRating ] = useState(0)

  const { mobile, mobileUa } = useContext(DevicesContext)

  const { givenOverallRating } = company

  const handleRatingUpdate = useCallback(({ rating }) => {
    setTemporaryRating(rating)
  }, [])

  const handleRatingModalClose = useCallback(() => {
    setTemporaryRating(0)
  }, [])

  const handleRatingClick = useCallback(() => {
    setTemporaryRating(givenOverallRating)
  }, [ givenOverallRating ])

  return (
    <>
      {givenOverallRating
        ? (
          <Button variant='transparent' onClick={handleRatingClick}>
            <Stars value={givenOverallRating} noDigits color={(mobile || mobileUa) ? void 0 : 'white'} />
          </Button>
        )
        : (
          <RatingField
            name='rating'
            color={mobile ? void 0 : 'white'}
            onUpdate={handleRatingUpdate}
          />
        )
      }

      {temporaryRating > 0 &&
        <RatingModal
          company={company}
          defaultOverall={temporaryRating}
          onRate={onRate}
          onClose={handleRatingModalClose}
        />
      }
    </>
  )
}

Rating.propTypes = {
  company: PropTypes.object,
  onRate: PropTypes.func
}

export default Rating
