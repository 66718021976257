import css from './EditForm.module.sass'

import React, { useCallback } from 'react'
import PropTypes from 'prop-types'

import { Form, FormRow, FormCol, TextField, TextArea } from '../Form'
import Text from "../Text"
import { BILLING_PATH } from "../../constants/routes"
import Link from "../Link"
import Alert from "../Alert"


const EditForm = ( { topic, formData, setFormData, submitForm, isPaidUserSubscription, isCustomTopic } ) => {
  const subTitleFontWeight = '400'

  const renderTemplateTopicToolTip = ( referencedObjects ) => {
    return <div className={ css.messageInToolTip }>
      Topic { referencedObjects } can be updated for Custom Topics only. { !isPaidUserSubscription &&
    <Link to={ BILLING_PATH }>Upgrade to unlock Custom Topics</Link> }
    </div>
  }


  const handleFieldUpdate = useCallback( ( patch ) => {
    setFormData( ( formData ) => ( {
        ...formData,
        ...patch
      } )
    )
  }, [ setFormData ] )

  const handleSubmit = useCallback( ( event ) => {
    event.preventDefault()
    submitForm()
  }, [ submitForm ] )

  return (
    <div className={css.container}>
      <Form onSubmit={handleSubmit}>

        <FormRow>
          <FormCol>
            { isCustomTopic &&
            <TextField
              label='Custom topic title *'
              subTitle={ <Text variant='standard' weight={ subTitleFontWeight } className={ css.subTitle }>
                This text will be displayed as a video bookmark
              </Text> }
              name='subject'
              value={ formData.subject }
              required
              onUpdate={ handleFieldUpdate }
            />
            }
            { !isCustomTopic &&
            <TextField label='Topic title'
                       subTitle={ <Text variant='standard' weight={ subTitleFontWeight }
                                        className={ css.subTitle }>
                         The topic title will display as a video bookmark
                       </Text> }
                       info={ renderTemplateTopicToolTip( 'title' ) }
                       name='subject' value={ topic.subject } disabled={ true }
            />
            }

          </FormCol>
        </FormRow>

        <FormRow offset='30'>
          <FormCol>
            { isCustomTopic &&
            <TextField
              label='Custom video prompt'
              name='question'
              subTitle={ <Text variant='standard' weight={ subTitleFontWeight } className={ css.subTitle }>
                This question will display as a prompt, and will not be visible to viewers.
              </Text> }
              value={ formData.question }
              onUpdate={ handleFieldUpdate }
            />
            }
            { !isCustomTopic &&
            <TextField label='Video prompt' name='question' value={ topic.question } disabled={ true }
                       subTitle={ <Text variant='standard' weight={ subTitleFontWeight }
                                        className={ css.subTitle }>
                         This question will display as a prompt, and will not be visible to viewers.
                       </Text> }
                       info={ renderTemplateTopicToolTip( 'prompt' ) }/>
            }
          </FormCol>
        </FormRow>

        <FormRow offset='30'>
          <FormCol>
            <TextArea
              disabled={isPaidUserSubscription === false}
              label='Teleprompter Script'
              name='teleprompt'
              subTitle={<>
                {isPaidUserSubscription === false &&
                <Alert variant='upgrade' centered textColor={'black'} noClose>
                  <Link variant={'inherit'} to={ BILLING_PATH }  >
                    Upgrade to unlock Teleprompter Script
                  </Link></Alert>
                }
                <Text variant='standard' weight={ subTitleFontWeight } className={ css.subTitle }>
                  Your script will display as a teleprompter, and will not be visible to viewers.
                </Text>
              </>}
              value={ formData.teleprompt }
              rows='6'
              counter='words'
              onUpdate={ handleFieldUpdate }
              placeholder={ 'Enter your script here.' }
            />
          </FormCol>
        </FormRow>

      </Form>
    </div>
  )
}

EditForm.propTypes = {
  topic: PropTypes.object,
  formData: PropTypes.object,
  setFormData: PropTypes.func,
  submitForm: PropTypes.func,
  isPaidUserSubscription: PropTypes.bool,
  isCustomTopic: PropTypes.bool
}

export default EditForm
