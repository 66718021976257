import css from './PaymentDetails.module.sass'

import React, { useState } from 'react'
import gql from 'graphql-tag'
import { Query } from 'react-apollo'

import Alert from './Alert'
import Loader from './Loader'
import Text from './Text'
import Link from './Link'
import Checkout from './Checkout'
import { Consumer as CurrentUserConsumer } from './CurrentUserContext'

import { formatDateTime } from '../helpers/dates'
import { formatPrice } from '../helpers/currencies'
import { formatGraphQLError } from '../helpers/errors'
import { PLAN_NAMES, PLAN_PRICES } from '../constants/plans'

const GET_CUSTOMER_CARD = gql`
  query {
    getCustomerCard {
      brand
      last4
    }
  }
`

const PaymentDetails = () => {
  const [ updateError, setUpdateError ] = useState()

  return (
    <div className={css.container}>
      <CurrentUserConsumer>
        {({ currentUser: { subscription } }) =>
          <Query
            query={GET_CUSTOMER_CARD}
            notifyOnNetworkStatusChange
            fetchPolicy='network-only'
          >
            {({ data, loading, error, refetch: refetchCard, networkStatus }) =>
              <Checkout>
                {({ checkout, updating }) => {
                  if (loading || updating || networkStatus === 4) {
                    return <div style={{ width: '100%' }}><Loader /></div>
                  }

                  if (error) {
                    return <Alert variant='error'>{formatGraphQLError(error)}</Alert>
                  }

                  return (
                    <>
                      {updateError && <Alert variant='error'>{formatGraphQLError(updateError)}</Alert>}

                      {subscription.cancelAtPeriodEnd &&
                        <div className={css.col}>
                          <Text tag='h2' variant='sectionTitle'>Status</Text>
                          <Text tag='p'>Canceled</Text>
                        </div>
                      }

                      <div className={css.col}>
                        <Text tag='h2' variant='sectionTitle'>
                          {subscription.cancelAtPeriodEnd ? 'Expires At' : 'Next Billing Date'}
                        </Text>
                        <Text tag='p'>
                          {formatDateTime(subscription.currentPeriodEnd, { month: 'short' })}
                        </Text>
                      </div>

                      {!subscription.cancelAtPeriodEnd &&
                        <div className={css.col}>
                          <Text tag='h2' variant='sectionTitle'>Next Bill</Text>
                          <Text tag='p'>
                            {PLAN_NAMES[subscription.plan]}
                            {' - '}
                            {formatPrice(PLAN_PRICES[subscription.plan])}
                          </Text>
                        </div>
                      }

                      {data.getCustomerCard &&
                        <div className={css.col}>
                          <Text tag='h2' variant='sectionTitle'>Payment Method</Text>

                          {data.getCustomerCard.brand} •••• {data.getCustomerCard.last4}

                          <span className={css.updateLink}>
                            <Link
                              onClick={() => {
                                checkout({
                                  onSuccess: () => {
                                    setUpdateError(null)
                                    refetchCard()
                                  },
                                  onError: error => setUpdateError(error)
                                })
                              }}
                            >
                              Update card details
                            </Link>
                          </span>
                        </div>
                      }
                    </>
                  )
                }}
              </Checkout>
            }
          </Query>
        }
      </CurrentUserConsumer>
    </div>
  )
}

export default PaymentDetails
