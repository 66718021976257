import css from './index.module.sass'

import React from 'react'
import PropTypes from 'prop-types'
import { Redirect } from 'react-router-dom'

import DeckManager from '../../DeckManager'
import Icon from '../../Icon'
import Text from '../../Text'

import Footer from './Footer'

import { injectParams } from '../../../helpers/routes'
import { CREATE_VIDEOS_PATH } from '../../../constants/routes'

const SlideForm = ({ topic, onCancel }) => {
  if (!topic.video) {
    return <Redirect to={injectParams(CREATE_VIDEOS_PATH, { topicId: topic.id, subject: topic.subject })} />
  }

  let { pitchdeckPage } = topic.video

  return (
    <div className={css.container}>
      <DeckManager
        header={
          <>
            <Text tag='p' variant='light' italic offset='half-bottom'>
              Add your slide deck in PDF format. (Up to 10 MB)
            </Text>
          </>
        }
        placeholder={
          <>
            <Icon name='presentation' width='96' />
            <Text tag='p' variant='standardLarger' offset='single-top' style={{ maxWidth: 300 }}>
              To add a slide to a video topic, upload your deck first, and then select a page that goes with that topic.
            </Text>
          </>
        }
        defaultPage={pitchdeckPage}
        selectedPage={pitchdeckPage}
        renderFooter={({ page }) =>
          <div className={css.footer}>
            <Footer topic={topic} currentPage={page} onCancel={onCancel} />
          </div>
        }
      />
    </div>
  )
}

SlideForm.propTypes = {
  topic: PropTypes.object,
  onCancel: PropTypes.func
}

export default SlideForm
