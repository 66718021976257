import React, { useContext, useEffect } from 'react'
import PropTypes from 'prop-types'

import CongratsModal from '../components/CongratsModal'
import Layout from '../components/Layout'
import MobileRecordingStub from '../components/MobileRecordingStub'
import PitchForm from '../components/PitchForm'
import WelcomeModal from '../components/WelcomeModal'

import DevicesContext from '../components/DevicesContext'
import {useQuery} from "react-apollo";
import {GET_USER_COMPANY} from "../constants/queries";
import CurrentUserContext from "../components/CurrentUserContext";

const CreateScreen = ({ location }) => {
  const { currentUser: { completedOnboardingProfile } } = useContext(CurrentUserContext)
  const { mobileUa } = useContext(DevicesContext)
  const { data: companyData, error, loading: getting, startPolling, stopPolling } = useQuery(GET_USER_COMPANY, {
    fetchPolicy: 'network-only'
  })

  useEffect(() => {
    if(companyData && companyData.company?.concatenatedVideo?.status !== "finished"){
      startPolling(5000)
    }

    return () => {
      stopPolling()
    }
  }, [ startPolling, stopPolling, companyData ])

  if (mobileUa) {
    return <MobileRecordingStub />
  }

  return (
    <Layout>
      <PitchForm company={companyData?.company} loading={getting} error={error} />
      {!completedOnboardingProfile && <WelcomeModal
          company={companyData?.company} loading={getting} error={error} />}
      {location.state?.initiatedFirstStitch && <CongratsModal />}
    </Layout>
  )
}

CreateScreen.propTypes = {
  location: PropTypes.object
}

export default CreateScreen
