import css from './TeamList.module.sass'

import React from 'react'
import PropTypes from 'prop-types'

import Member from './Member'
import ReorderableRows from '../../ReorderableRows'

const TeamList = ({ members, onReorder }) => {
  if (!members.length) {
    return null
  }

  return (
    <ReorderableRows
      items={members}
      renderContainer={
        ({ children }) =>
          <table className={css.table}>
            <tbody>
              {children}
            </tbody>
          </table>
      }
      renderItem={
        ({ item, dragged, onDragStart }) =>
          <Member
            key={item.id}
            member={item}
            dragging={dragged}
            onDragStart={onDragStart}
          />
      }
      itemSelector='tr'
      onReorder={onReorder}
    />
  )
}

TeamList.propTypes = {
  members: PropTypes.array,
  onReorder: PropTypes.func
}

export default TeamList
