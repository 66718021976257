import css from './RatingModal.module.sass'

import React, { useState, useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useQuery, useMutation } from 'react-apollo'
import gql from 'graphql-tag'
import omit from 'lodash.omit'

import Button from './Button'
import { Form, FormFooter, FormRow, FormCol, TextArea, RatingField } from './Form'
import Modal from './Modal'
import Text from './Text'
import Ionicon from './Ionicon'
import Loader from './Loader'

const GET_INVESTOR_COMPANY_RATING = gql`
  query getInvestorCompanyRating ($company: UUID) {
    getInvestorCompanyRating(company: $company) {
      overall
      pitchVideo
      pitchDeck
      highlightsSection
      teamSection
      review
    }
  }
`

const RATE_COMPANY = gql`
  mutation rateCompany ($input: RateCompanyMutationInput!) {
    rateCompany(input: $input) {
      obj {
        id
        overall
        updatedAt
      }
      errors {
        field
        messages
      }
    }
  }
`

const RatingModal = ({ company, defaultOverall, onClose, onRate }) => {
  const [ formData, setFormData ] = useState({
    overall: defaultOverall
  })

  const { data: ratingData, loading: fetching } = useQuery(GET_INVESTOR_COMPANY_RATING, {
    variables: { company: company.id },
    fetchPolicy: 'network-only'
  })

  useEffect(() => {
    if (ratingData) {
      setFormData({
        ...omit( ratingData.getInvestorCompanyRating, [ '__typename' ] ),
        overall: defaultOverall
      })
    }
  }, [ ratingData, defaultOverall ])

  const [ rateCompany, { saving } ] = useMutation( RATE_COMPANY, {
    variables: {
      input: {
        company: company.id,
        ...formData
      }
    },
    onCompleted: ( { rateCompany } ) => {
      if ( rateCompany.errors ) {
        return console.error( rateCompany.errors )
      }

      onRate( rateCompany.obj.overall )
      onClose()
    }
  })

  const handleFieldUpdate = useCallback((patch) => {
    setFormData(x => ({ ...x, ...patch }))
  }, [])

  const handleSubmit = useCallback((event) => {
    event.preventDefault()
    rateCompany()
  }, [ rateCompany ])

  return (
    <Modal
      variant='welcome'
      title={<>
        <Text tag='h1' variant='h2' color='white'>Rate and review {company.name} <i>Pitchtape</i></Text>

        <Button variant='icon' className={css.btnClose} onClick={onClose}>
          <Ionicon name='close' size='32' color='white' />
        </Button>
      </>}
      onOverlayClick={onClose}
      noButtons
    >
      {fetching
        ? <Loader />
        : (
          <Form onSubmit={handleSubmit}>
            <FormRow>
              <FormCol>
                <Text tag='h2' variant='blockTitle' offset='half-bottom'>Overall <i>Pitchtape</i> rating</Text>
                <RatingField
                  variant='large'
                  name='overall'
                  value={formData.overall}
                  focused
                  required
                  onUpdate={handleFieldUpdate}
                />
              </FormCol>
            </FormRow>

            <FormRow offset='30'>
              <FormCol>
                <Text tag='h2' variant='blockTitle' offset='half-bottom'>
                  Rate <i>Pitchtape</i> sections <Text italic weight='300'>(optional)</Text>
                </Text>

                <FormRow offset='15'>
                  <FormCol>
                    <Text tag='h3'>Video pitch</Text>
                    <RatingField
                      name='pitchVideo'
                      value={formData.pitchVideo}
                      clearable
                      onUpdate={handleFieldUpdate}
                    />
                  </FormCol>
                </FormRow>

                <FormRow offset='15'>
                  <FormCol>
                    <Text tag='h3'>Pitch deck</Text>
                    <RatingField
                      name='pitchDeck'
                      value={formData.pitchDeck}
                      clearable
                      onUpdate={handleFieldUpdate}
                    />
                  </FormCol>
                </FormRow>

                <FormRow offset='15'>
                  <FormCol>
                    <Text tag='h3'>Team</Text>
                    <RatingField
                      name='teamSection'
                      value={formData.teamSection}
                      clearable
                      onUpdate={handleFieldUpdate}
                    />
                  </FormCol>
                </FormRow>

                <FormRow offset='15'>
                  <FormCol>
                    <Text tag='h3'>Company highlights</Text>
                    <RatingField
                      name='highlightsSection'
                      value={formData.highlightsSection}
                      clearable
                      onUpdate={handleFieldUpdate}
                    />
                  </FormCol>
                </FormRow>
              </FormCol>
            </FormRow>

            <FormRow offset='30'>
              <FormCol>
                <Text tag='h2' variant='blockTitle' offset='quarter-bottom'>
                  Write a review <Text italic weight='300'>(optional)</Text>
                </Text>
                <Text tag='p' offset='single-bottom'>Leave anonymous feedback for the startup founders</Text>
                <TextArea
                  name='review'
                  value={formData.review}
                  rows='5'
                  onUpdate={handleFieldUpdate}
                />
              </FormCol>
            </FormRow>

            <FormFooter offset='30'>
              <Button type='submit' variant='primary' disabled={saving}>Submit</Button>
            </FormFooter>
          </Form>
        )
      }
    </Modal>
  )
}

RatingModal.propTypes = {
  company: PropTypes.object,
  defaultOverall: PropTypes.number,
  onClose: PropTypes.func,
  onRate: PropTypes.func
}

export default RatingModal
