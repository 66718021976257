import React from 'react'
import PropTypes from 'prop-types'
import gql from 'graphql-tag'
import { Query } from 'react-apollo'
import omit from 'lodash.omit'
import get from 'lodash.get'

import Ionicon from '../Ionicon'
import Dropdown from './Dropdown'

import { formatLocation } from '../../helpers/strings'
import { LOCATION_FRAGMENT } from '../../constants/fragments'

const GET_LOCATIONS = gql`
  query getLocations ($first: Int, $last: Int, $before: String, $after: String, $name: String!) {
    getLocations (first: $first, last: $last, before: $before, after: $after, name: $name) {
      edges {
        node {
          ...location
        }
        cursor
      }
      pageInfo {
        endCursor
      }
    }
  }
  ${LOCATION_FRAGMENT}
`

// making sure accepted props won't get cleared by some optimizer
const PROP_TYPES = {
  name: PropTypes.string,
  value: PropTypes.array,
  error: PropTypes.oneOfType([ PropTypes.array, PropTypes.string ]),
  onValid: PropTypes.func,
  onInvalid: PropTypes.func,
  onUpdate: PropTypes.func
}

class LocationField extends React.PureComponent {
  static propTypes = PROP_TYPES

  state = {
    options: null,
    error: null
  }

  filterTimeout = null

  handleAutocomplete = (filter) => {
    if (this.state.options !== null) {
      this.setState({ options: null, query: '' })
    }

    clearTimeout(this.filterTimeout)

    if (filter) {
      this.filterTimeout = setTimeout(() => {
        this.setState({ query: filter })
      }, 700)
    }
  }

  render () {
    const { name, value, onUpdate } = this.props
    const { query, options } = this.state
    const dropdownProps = omit(this.props, Object.keys(PROP_TYPES))

    const firstValue = value && value[0]

    return (
      <Query
        query={GET_LOCATIONS}
        skip={!query}
        variables={{
          name: query,
          first: 10
        }}
        onCompleted={(data) => {
          const edges = get(data, 'getLocations.edges')

          this.setState({
            options: edges && edges.map(e => ({
              value: e.node,
              label: formatLocation(e.node)
            }))
          })
        }}
        notifyOnNetworkStatusChange
      >
        {({ data, loading, fetchMore, networkStatus }) => {
          return (
            <Dropdown
              name={name}
              value={firstValue}
              options={options}
              loading={loading || networkStatus === 3}
              valueIdKey='id'
              icon={<Ionicon name='pin' color='deepBlue' />}
              focusedPlaceholder='Type and select'
              renderLabel={value => formatLocation(value)}
              onAutocomplete={this.handleAutocomplete}
              onUpdate={(obj) => {
                onUpdate({
                  [name]: obj[name] ? [ obj[name] ] : []
                })
              }}
              onFetchMore={() => {
                if (this.fetchingMore) {
                  return
                }

                this.fetchingMore = true

                return fetchMore({
                  variables: {
                    first: 10,
                    after: data.getLocations.pageInfo.endCursor
                  },
                  updateQuery: (prev, { fetchMoreResult: next }) => {
                    return {
                      getLocations: {
                        ...prev.getLocations,
                        edges: [
                          ...prev.getLocations.edges,
                          ...next.getLocations.edges
                        ],
                        pageInfo: next.getLocations.pageInfo
                      }
                    }
                  }
                })
                  .then(() => { this.fetchingMore = false })
                  .catch(() => { this.fetchingMore = false })
              }}
              {...dropdownProps}
            />
          )
        }}
      </Query>
    )
  }
}

export default LocationField
