import css from './TopicDragHandle.module.sass'

import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

const TopicDragHandle = ({ disabled, onDragStart }) => {
  const containerRef = useRef()

  useEffect(() => {
    const dragEl = containerRef.current

    dragEl.addEventListener('touchstart', onDragStart, { passive: false })

    return () => {
      dragEl.removeEventListener('touchstart', onDragStart)
    }
  }, [ onDragStart ])

  return (
    <div
      className={classNames(css.container, {
        [css.disabled]: disabled
      })}
      onMouseDown={onDragStart}
      ref={containerRef}
    >
      <i className={css.icon} />
    </div>
  )
}

TopicDragHandle.propTypes = {
  disabled: PropTypes.bool,
  onDragStart: PropTypes.func
}

export default TopicDragHandle
