import css from './index.module.sass'

import React, { useState, useRef } from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'

import { getBounds } from '../../helpers/ui'
import { moveItemInArray } from '../../helpers/arrays'

let portal

const ReorderableRows = ({ items, renderContainer, renderItem, itemSelector, disabled, onReorder }) => {
  const [ nextIndex, setNextIndex ] = useState()
  const [ draggedIndex, setDraggedIndex ] = useState(null)
  const [ reorderedItems, setReorderedItems ] = useState()

  const containerRef = useRef()
  const draggedRef = useRef()

  const initPortal = () => {
    if (!portal) {
      const div = document.createElement('div')
      div.className = 'reorderable-rows-portal'
      document.body.appendChild(div)
      portal = div
    }
  }

  const handleDragStart = (event, _draggedIndex) => {
    event.preventDefault()

    if (disabled) {
      return
    }

    initPortal()

    let _nextIndex

    const rowSet = containerRef.current.querySelectorAll(itemSelector)
    const rowsBounds = [ ...rowSet ].map(row => {
      const { top, bottom } = getBounds(row)
      return { top, bottom }
    })

    const touchedRow = event.currentTarget.parentNode

    const { pageX: cursorStartX, pageY: cursorStartY } = event
    const bounds = getBounds(event.currentTarget)
    const diffY = event.pageY - bounds.top
    const diffX = event.pageX - bounds.left
    const halfRowHeight = bounds.height / 2

    const reposition = (cursorX, cursorY) => {
      const nextTop = cursorY - diffY
      const nextCenter = nextTop + halfRowHeight

      draggedRef.current.style.width = touchedRow.clientWidth + 'px'
      draggedRef.current.style.top = nextTop + 'px'
      draggedRef.current.style.left = (cursorX - diffX) + 'px'

      for (let i = 0; i < rowsBounds.length; i++) {
        const bounds = rowsBounds[i]
        if (bounds.top < nextCenter && bounds.bottom >= nextCenter) {
          _nextIndex = i
          setNextIndex(i)
          setReorderedItems(moveItemInArray(items, _draggedIndex, i))
          return
        }
      }
    }

    const copyCellWidths = () => {
      const clonedCells = draggedRef.current.querySelectorAll('td')
      const originalCells = touchedRow.querySelectorAll('td')

      for (let i = 0; i < clonedCells.length; i++) {
        clonedCells[i].style.width = originalCells[i].clientWidth + 'px'
      }
    }

    setTimeout(() => {
      reposition(cursorStartX, cursorStartY)
      itemSelector === 'tr' && copyCellWidths()
    }, 0)

    const handleMouseMove = (event) => {
      reposition(event.pageX, event.pageY)
    }

    const handleMouseUp = () => {
      document.removeEventListener('mousemove', handleMouseMove)
      document.removeEventListener('touchmove', handleMouseMove)
      document.removeEventListener('mouseup', handleMouseUp)
      document.removeEventListener('touchend', handleMouseUp)

      setReorderedItems(null)
      setNextIndex(null)
      setDraggedIndex(null)

      if (_nextIndex !== _draggedIndex) {
        onReorder(_draggedIndex, _nextIndex, items[_draggedIndex])
      }
    }

    document.addEventListener('mousemove', handleMouseMove)
    document.addEventListener('touchmove', handleMouseMove)
    document.addEventListener('mouseup', handleMouseUp)
    document.addEventListener('touchend', handleMouseUp)

    setDraggedIndex(_draggedIndex)
  }

  return (
    <div ref={containerRef}>
      {renderContainer({
        children:
          (reorderedItems || items).map((item, index) =>
            renderItem({
              item,
              dragged: nextIndex === index,
              draggingDisabled: disabled,
              onDragStart: (event) => { handleDragStart(event, index) }
            }))
      })}

      {draggedIndex !== null &&
        ReactDOM.createPortal(
          (
            <div className={css.dragged} ref={draggedRef}>
              {renderContainer({
                dragged: true,
                children: renderItem({ item: items[draggedIndex] })
              })}
            </div>
          ),
          portal
        )
      }
    </div>
  )
}

ReorderableRows.displayName = 'ReorderableRows'

ReorderableRows.propTypes = {
  items: PropTypes.array,
  renderContainer: PropTypes.func,
  renderItem: PropTypes.func,
  itemSelector: PropTypes.string,
  disabled: PropTypes.bool,
  onReorder: PropTypes.func
}

export default ReorderableRows
