import css from "./VideoPreviewHeader.module.sass";
import {isActivePaidPlan} from "../../helpers/subscription";
import Button from "../Button";
import Ionicon from "../Ionicon";
import {BILLING_PATH, CREATE_VIDEOS_PATH, PITCHTAPE_PATH} from "../../constants/routes";
import CompanyUrlCopier from "../CompanyUrlCopier";
import React, {useContext} from "react";
import PropTypes from "prop-types";
import CurrentUserContext from "../CurrentUserContext";
import {useDownloadVideoUrl} from "../../helpers/hooks";
import Wrap from "../Wrap";
import Tooltip from "../Tooltip";
import Link from "../Link";

const VideoPreviewHeader = ({ company, editable, shareable }) => {
    const {currentUser: {subscription}} = useContext(CurrentUserContext)
    const downloadVideoUrl = useDownloadVideoUrl(company)

    return (
        <div className={css.buttons}>

            <Wrap
                in={(children) =>
                    <Tooltip
                        hoverable
                        content={<>
                            <Link variant='inherit' to={BILLING_PATH}>Upgrade</Link> to download your video
                        </>}
                    >
                        {children}
                    </Tooltip>
                }
                when={!isActivePaidPlan(subscription)}
            >
                <Button
                    variant='feedActionPreview'
                    icon={<Ionicon name='download' color='white' size='24' />}
                    href={downloadVideoUrl}
                    external
                    disabled={!isActivePaidPlan(subscription)}
                >
                    Download
                </Button>
            </Wrap>

            {editable &&
            <Button
                variant='feedActionPreview'
                icon={
                    <Ionicon name='create' color='white' size='24'/>
                }
                to={CREATE_VIDEOS_PATH}
            >
                Edit Video
            </Button>
            }

            <Button
                variant='feedActionPreview'
                icon={
                    <Ionicon name='today' color='white' size='24' />
                }
                to={PITCHTAPE_PATH}
            >
                View Pitch
            </Button>

            {shareable &&
            <CompanyUrlCopier>
                <Button
                    variant='feedActionPrimary'
                    icon={
                        <Ionicon name='share' color='white' size='24' style={{left: -2}}/>
                    }
                >
                    Share
                </Button>
            </CompanyUrlCopier>
            }
        </div>)
}

VideoPreviewHeader.propTypes = {
    company: PropTypes.object,
    height: PropTypes.number,
    editable: PropTypes.bool,
    shareable: PropTypes.bool
}

export default VideoPreviewHeader
