import React, { useMemo, useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'
import { withApollo } from 'react-apollo'

import ChatLayout from '../ChatLayout'
import ConnectionList from '../ConnectionList'
import Chat from '../Chat'
import Text from '../Text'
import Link from '../Link'
import NoInvestorSelected from './NoInvestorSelected'

import { toWithParams } from '../../helpers/routes'
import { formatLocations } from '../../helpers/strings'
import { CONNECTIONS_PATH, BILLING_PATH } from '../../constants/routes'
import { GET_CONNECTED_INVESTORS } from '../../constants/queries'

const FounderConnections = ({
  client, selectedConnectionId,
  investorsData, loadingInvestors, loadingMoreInvestors, investorsError,
  investorData, loadingInvestor, investorError,
  onFetchMoreInvestors, onConnectionSeen
}) => {
  const investors = investorsData && investorsData.investors
  const connection = investorData && investorData.getConnectedInvestor

  const handleFetchMore = useCallback(() => {
    return onFetchMoreInvestors({
      variables: {
        first: 10,
        after: investors.pageInfo.endCursor
      },
      updateQuery: (prev, { fetchMoreResult: next }) => {
        return {
          investors: {
            ...prev.investors,
            edges: [
              ...prev.investors.edges,
              ...next.investors.edges
            ],
            pageInfo: next.investors.pageInfo
          }
        }
      }
    })
  }, [ investors, onFetchMoreInvestors ])

  const getInvestorName = (investorProfile) => {
    const { createdBy: { firstName, lastName } } = investorProfile

    return `${firstName} ${lastName}`
  }

  const connections = useMemo(() => {
    return investors && investors.edges.map(({ node: { id, seen, investorProfile, chat } }) => ({
      id: id,
      chatUrl: toWithParams(CONNECTIONS_PATH, { connectionId: id }),
      selected: id === selectedConnectionId,
      label: getInvestorName(investorProfile),
      sublabel: investorProfile.name,
      unseen: !seen,
      count: chat && chat.unreadMessages
    }))
  }, [ investors, selectedConnectionId ])

  const chatConnection = useMemo(() => {
    if (!connection) {
      return
    }

    const { investorProfile } = connection

    return {
      name: getInvestorName(investorProfile),
      description: investorProfile.name,
      dateConnected: new Date(connection.dateConnected),
      investorProfile,
      details: [
        { label: 'Location', value: formatLocations(investorProfile.locations) },
        { label: 'Investor Type', value: investorProfile.investorTypes },
        { label: 'Industries', value: investorProfile.industries },
        { label: 'Stage', value: investorProfile.stages }
      ]
    }
  }, [ connection ])

  useEffect(() => {
    if (!connection || loadingInvestors) {
      return
    }

    const { seen, chat } = connection
    const unreadMessages = chat ? chat.unreadMessages : 0

    const updateCache = () => {
      const { investors } = client.readQuery({ query: GET_CONNECTED_INVESTORS })

      const edges = investors.edges.map(edge => {
        const { node } = edge

        if (connection.id === node.id) {
          return {
            ...edge,
            node: {
              ...edge.node,
              seen: true,
              chat: edge.node.chat && {
                ...edge.node.chat,
                unreadMessages: 0
              }
            }
          }
        }

        return edge
      })

      client.writeQuery({
        query: GET_CONNECTED_INVESTORS,
        data: {
          investors: {
            ...investors,
            totalUnseen: investors.totalUnseen - (!seen ? 1 : 0),
            totalUnreadMessages: investors.totalUnreadMessages - unreadMessages,
            edges
          }
        }
      })
    }

    if (!seen) {
      onConnectionSeen({
        variables: {
          connections: [ connection.id ]
        },
        update: () => updateCache()
      })
    } else if (unreadMessages) {
      updateCache()
    }
  }, [ client, connection, loadingInvestors, onConnectionSeen ])

  return (
    <ChatLayout
      listLabel='Investors'
      list={
        <ConnectionList
          connections={connections}
          loading={loadingInvestors}
          loadingMore={loadingMoreInvestors}
          error={investorsError}
          selectedId={selectedConnectionId}
          noConnectionsMessage={
            investors && investors.totalUnseen
              ? <>
                  <b>{investors.totalUnseen}</b> investor has shown interest.<br />
                  <Link to={BILLING_PATH}>Upgrade</Link> to start a chat.
                </>
              : 'Investors that connect with you will be listed here.'
          }
          onFetchMore={investors && investors.pageInfo.hasNextPage ? handleFetchMore : void 0}
        />
      }
      connectionSelected={Boolean(selectedConnectionId)}
      hasConnections={connections && Boolean(connections.length || investors.totalUnseen)}
      chat={
        connections && (
          connections.length
            ? (
              <Chat
                connection={chatConnection}
                loadingConnection={loadingInvestor}
                connectionError={investorError}
                connectionId={selectedConnectionId}
                emptyMessage={chatConnection &&
                  <>
                    <Text tag='p' offset='half-bottom'>{chatConnection.name} has connected with you.</Text>
                    <Text tag='p' offset='half-bottom'>
                      Type your message below to start a private conversation.
                    </Text>
                    <Text tag='p' color='gray'>For example, say something like:</Text>
                    <Text tag='p' color='gray'>
                      “Thanks for connecting! I&apos;d love to set up a call or video chat.
                    </Text>
                    <Text tag='p' color='gray'>Do you have any availability in the coming days?”</Text>
                  </>
                }
                noChatMessage={
                  'Select an investor from your connections list to learn more ' +
                  'about them and to start or respond to a private conversation.'
                }
              />
            )
            : <NoInvestorSelected />
        )
      }
      backUrl={toWithParams(CONNECTIONS_PATH)}
    />
  )
}

FounderConnections.propTypes = {
  client: PropTypes.object,
  selectedConnectionId: PropTypes.string,
  investorsData: PropTypes.object,
  loadingInvestors: PropTypes.bool,
  loadingMoreInvestors: PropTypes.bool,
  investorsError: PropTypes.object,
  investorData: PropTypes.object,
  loadingInvestor: PropTypes.bool,
  investorError: PropTypes.object,
  onFetchMoreInvestors: PropTypes.func,
  onConnectionSeen: PropTypes.func
}

export default withApollo(FounderConnections)
