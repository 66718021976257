import css from './index.module.sass'

import React from 'react'
import PropTypes from 'prop-types'

import Well from '../Well'

import Section from './Section'
import Interactions from './Interactions'
import TotalViews from './TotalViews'
import AverageViewTime from './AverageViewTime'
import AvgRating from './AvgRating'
import Reviews from './Reviews'
// import VideosByViews from './VideosByViews'
// import VideosByAverageTime from './VideosByAverageTime'

const FounderAnalytics = ({
  interactionsLastWeekLoading,
  interactionsAllTimeLoading,
  companyAnalyticsLoading,
  interactionsLastWeek,
  interactionsAllTime,
  totalViews,
  totalViewsLastWeek,
  avgViewTime,
  avgViewTimeLastWeek,
  avgRating,
  reviewsLoading,
  reviews,
  hasMoreReviews,
  onLoadMoreReviews
  // videos
}) => {
  return (
    <>
      <Well title={<>Pitch Analytics</>}>
        <div className={css.analyticsGrid}>
          <Section title='Investor Decision (Last 7 Days)' loading={interactionsLastWeekLoading}>
            <Interactions {...interactionsLastWeek} />
          </Section>

          <Section title='Investor Decision (All Time)' loading={interactionsAllTimeLoading}>
            <Interactions {...interactionsAllTime} />
          </Section>

          <Section title={<>Total Pitch Views</>} loading={companyAnalyticsLoading}>
            <TotalViews totalViews={totalViews} viewsLastWeek={totalViewsLastWeek} />
          </Section>

          <Section title={<>Average Time Per Pitch View</>} loading={companyAnalyticsLoading}>
            <AverageViewTime allTime={avgViewTime} lastWeek={avgViewTimeLastWeek} />
          </Section>

          {/* <Section title='Most Viewed Video (All Time)' loading={companyAnalyticsLoading}>
            <VideosByViews videos={videos} />
          </Section>

          <Section title='Average Time Per Video (All Time)' loading={companyAnalyticsLoading}>
            <VideosByAverageTime videos={videos} />
          </Section> */}
        </div>
      </Well>

      <Well title='Investor Ratings'>
        <AvgRating loading={companyAnalyticsLoading} avgRating={avgRating} />
      </Well>

      <Well title='Investor Reviews'>
        <Reviews
          loading={reviewsLoading}
          reviews={reviews}
          hasMore={hasMoreReviews}
          onLoadMore={onLoadMoreReviews}
        />
      </Well>
    </>
  )
}

FounderAnalytics.propTypes = {
  interactionsLastWeekLoading: PropTypes.bool,
  interactionsAllTimeLoading: PropTypes.bool,
  companyAnalyticsLoading: PropTypes.bool,
  interactionsLastWeek: PropTypes.object,
  interactionsAllTime: PropTypes.object,
  totalViews: PropTypes.number,
  totalViewsLastWeek: PropTypes.number,
  avgViewTime: PropTypes.number,
  avgViewTimeLastWeek: PropTypes.number,
  avgRating: PropTypes.object,
  reviewsLoading: PropTypes.bool,
  reviews: PropTypes.array,
  hasMoreReviews: PropTypes.bool,
  onLoadMoreReviews: PropTypes.func
  // videos: PropTypes.array
}

export default FounderAnalytics
