import css from './SignInForm.module.sass'

import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

import { Form, FormRow, FormCol, EmailField, PasswordField } from './Form'
import Button from './Button'
import Link from './Link'
import Text from './Text'
import Alert from './Alert'

import { MISSING_ERROR } from '../constants/errors'
import { GTM_FORM_SIGN_IN } from '../constants/gtm'
import { PASSWORD_PATH } from '../constants/routes'
import { formatGraphQLError } from '../helpers/errors'

class SignInForm extends PureComponent {
  static propTypes = {
    error: PropTypes.object,
    loading: PropTypes.bool,
    onUserSignIn: PropTypes.func
  }

  state = {
    formData: {
      email: '',
      password: ''
    },
    valid: false
  }

  handleFieldUpdate = (patch) => {
    this.setState({
      formData: {
        ...this.state.formData,
        ...patch
      }
    })
  }

  render () {
    const { error, loading } = this.props
    const { formData, valid } = this.state

    return (
      <Form
        gtm={GTM_FORM_SIGN_IN}
        onSubmit={(event) => {
          event.preventDefault()

          if (valid && !loading) {
            this.props.onUserSignIn(formData)
          }
        }}
        onValid={() => this.setState({ valid: true })}
        onInvalid={() => this.setState({ valid: false })}
      >
        {error && <Alert variant='error' offset='double'>{formatGraphQLError(error)}</Alert>}

        <FormRow>
          <FormCol>
            <EmailField
              label='Email'
              name='email'
              value={formData.email}
              required
              errorsOverride={{
                [MISSING_ERROR]: 'Please enter your email address.'
              }}
              onUpdate={this.handleFieldUpdate}
            />
          </FormCol>
        </FormRow>

        <FormRow offset='20'>
          <FormCol>
            <PasswordField
              label='Password'
              afterInput={
                <div className={css.forgotPassword}>
                  <Link to={PASSWORD_PATH}>
                    <Text variant='standard' color='black' opacity='50'>
                      Forgot Password?
                    </Text>
                  </Link>
                </div>
              }
              name='password'
              value={formData.password}
              required
              errorsOverride={{
                [MISSING_ERROR]: 'Please enter your password.'
              }}
              onUpdate={this.handleFieldUpdate}
            />
          </FormCol>
        </FormRow>

        <FormRow offset='35'>
          <FormCol>
            <Button variant='primary' type='submit' block disabled={loading}>
              Log In
            </Button>
          </FormCol>
        </FormRow>
      </Form>
    )
  }
}

export default SignInForm
