import css from './Topic.module.sass'

import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import Button from '../../Button'
import Ionicon from '../../Ionicon'
import Link from '../../Link'
import Text from '../../Text'

import TopicDragHandle from './TopicDragHandle'

import {
  CREATE_VIDEOS_PATH, CREATE_VIDEOS_EDIT_PATH, CREATE_VIDEOS_NEW_PATH, PRACTICE_PATH,
  // CREATE_VIDEOS_SLIDE_PATH
} from '../../../constants/routes'
import Modal from "../../Modal"
import { durationToSeconds } from '../../../helpers/videos'
import { formatSecondsAsTime } from '../../../helpers/dates'


const Topic = ({ topic, active, done, dragging, draggingDisabled, practice, onDragStart, deleting, onRemove }) => {
  const linkParams = topic ? { topicId: topic.id, subject: topic.subject } : void 0
  const [confirmDeleteTopic, setConfirmDeleteTopic] = useState(false)
  const activeDuration = topic?.video || false

  const handleRemove = useCallback(() => {
    if (deleting) {
      return
    }
    if (topic.video && !confirmDeleteTopic && !practice) {
      // This will trigger to prompt the confirm modal but if it's already true will go to delete it
      setConfirmDeleteTopic(true)
      return
    }
    if (topic.id) {
      onRemove && onRemove(topic.id)
    }
  }, [deleting, onRemove, topic, confirmDeleteTopic, practice])

  return (
    <div className={classNames(css.container, {
      [css.deleting]: deleting,
      [css.dragging]: dragging,
      [css.adding]: !topic
    })}>

      {confirmDeleteTopic && !practice &&
        <Modal
          buttons={<>
            <Button variant='red' onClick={handleRemove} disabled={deleting}>
              Yes, delete
            </Button>
            <Button variant='outline' onClick={() => {
              setConfirmDeleteTopic(false)
            }
            }>
              Cancel
            </Button>
          </>}
        >
          <Text tag='p' variant='h3'>
            Are you sure you want to delete this topic and video segment?
          </Text>
          <Text tag='p' offset='double-top' variant='standardLarger'>
            <Ionicon name='alert' color='red' size='24' style={{ marginRight: '0.5em' }} />
            This action is not reversible and the video will be permanently deleted.
          </Text>
        </Modal>
      }
      {topic && !practice &&
        <TopicDragHandle disabled={draggingDisabled} onDragStart={onDragStart} />
      }

      <Link
        nav
        exact
        to={practice ? PRACTICE_PATH : topic ? CREATE_VIDEOS_PATH : CREATE_VIDEOS_NEW_PATH}
        params={linkParams}
        color='deepBlue'
        className={css.videoLink}
        activeClassName={css.videoLinkActive}
      >
        {({ active: navActive }) =>
          <>
            <Ionicon
              name={done ? 'play' : 'videocam'}
              size='24'
              color={topic && navActive ? 'red' : (done ? 'deepBlue' : 'lightGray')}
              offset='right-10'
            />

            <Text
              className={css.videoLinkLabel}
              variant='standardLarger'
              weight={active ? '500' : '400'}
              truncated
            >
              {topic ? topic.subject : 'New topic'}

            </Text>
            { activeDuration &&
              <Text 
                variant="small" 
                color={topic && navActive ? 'red' : 'black'}
                > 
                ({topic && formatSecondsAsTime(durationToSeconds(topic?.video?.duration))})
              </Text>
            }
          </>
        }
      </Link>

      {!practice &&
        <>
          {/*{topic &&*/}
          {/*  <Button*/}
          {/*    to={CREATE_VIDEOS_SLIDE_PATH}*/}
          {/*    params={linkParams}*/}
          {/*    variant='iconSmall'*/}
          {/*    className={css.btnSlide}*/}
          {/*  >*/}
          {/*    {({ active: navActive }) => {*/}
          {/*      const hasSlide = topic.video?.pitchdeckPage != null*/}

          {/*      return (*/}
          {/*        <div className={navActive ? css.slideIconActive : css.slideIcon}>*/}
          {/*          <span className={hasSlide ? css.slideBadgeActive : css.slideBadge}>*/}
          {/*            {hasSlide ? topic.video.pitchdeckPage : '+'}*/}
          {/*          </span>*/}
          {/*        </div>*/}
          {/*      )*/}
          {/*    }}*/}
          {/*  </Button>*/}
          {/*}*/}

          <Button
            to={topic ? CREATE_VIDEOS_EDIT_PATH : CREATE_VIDEOS_NEW_PATH}
            params={linkParams}
            variant='iconSmall'
          >
            {({ active: navActive }) =>
              <Ionicon name='create' size='16' color={navActive ? 'red' : 'deepBlue'} />
            }
          </Button>

          <Button variant='iconSmall' onClick={handleRemove}>
            <Ionicon name='close' size='16' color='deepBlue' />
          </Button>
        </>
      }
    </div>
  )
}

Topic.propTypes = {
  topic: PropTypes.object,
  active: PropTypes.bool,
  done: PropTypes.bool,
  dragging: PropTypes.bool,
  practice: PropTypes.bool,
  draggingDisabled: PropTypes.bool,
  onDragStart: PropTypes.func,
  deleting: PropTypes.bool,
  onRemove: PropTypes.func
}

export default Topic
