import css from './Button.module.sass'

import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import Icon from './Icon'
import Link from './Link'

const Button = ({
  variant = 'base', className, offset, padding, block, type, gtm, disabled, active, children,
  icon, iconPosition, iconMaxHeight, iconOpacity, iconOffset,
  ...linkProps
}) => {
  const extraProps = {
    className: classNames(
      gtm && `gtm-button-${gtm}`,
      css[variant],
      className,
      css[`padding-${padding}`],
      css[`offset-${offset}`], {
        [css.block]: block,
        [css.disabled]: disabled,
        [css.active]: active
      }
    )
  }

  const renderChildren = () => {
    if (icon) {
      return (
        <>
          {children && iconPosition === 'after' && <span>{children}</span>}

          <span
            className={classNames(css.iconContainer, css[`icon-offset-${iconOffset}`], { [css.iconPositionAfter]: iconPosition === 'after' })}
            style={iconMaxHeight && { height: iconMaxHeight }}
          >
            {typeof icon === 'string'
              ? (
                <Icon
                  name={icon}
                  opacity={iconOpacity}
                  style={iconMaxHeight && { maxHeight: iconMaxHeight }}
                />
              )
              : icon
            }
          </span>

          {children && iconPosition !== 'after' && <span>{children}</span>}
        </>
      )
    }

    return children
  }

  if (linkProps.to || linkProps.href) {
    if (disabled) {
      return <span {...extraProps}>{renderChildren()}</span>
    }

    return (
      <Link variant={null} activeClassName={css.active} {...linkProps} {...extraProps}>
        {renderChildren()}
      </Link>
    )
  }

  return (
    <button {...linkProps} disabled={disabled} {...extraProps} type={type || 'button'}>
      {renderChildren()}
    </button>
  )
}

Button.propTypes = {
  children: PropTypes.oneOfType([ PropTypes.node, PropTypes.func ]),
  variant: PropTypes.oneOf([
    'primary',
    'primaryNarrow',
    'primarySmaller',
    'secondary',
    'white',
    'red',
    'tooltipWhite',
    'outline',
    'outlineRed',
    'outlineNarrow',
    'segment',
    'segmentCollapse',
    'green',
    'icon',
    'iconSmall',
    'iconWhite',
    'iconBlack',
    'iconPrimary',
    'iconOutline',
    'iconFooter',
    'iconEditor',
    'transparent',
    'iconOnTop',
    'iconOnTopRound',
    'iconOnTopRoundPrimary',
    'iconOnTopRoundHighlight',
    'iconOnLeft',
    'tab',
    'mobileNavbar',
    'feedAction',
    'feedActionPrimary',
    'play',
    'playDisabled',
    'record',
    'recordStop',
    'tape',
    'templateTopic',
    'feedActionPreview'
  ]),
  className: PropTypes.string,
  offset: PropTypes.oneOf([
    'half-left',
    'half-right',
    'single-left',
    'single-right',
    'double-left',
    'double-right',
    'double-top'
  ]),
  padding: PropTypes.oneOf([
    'half',
    'single',
    'double'
  ]),
  gtm: PropTypes.string,
  type: PropTypes.string,
  icon: PropTypes.node,
  iconPosition: PropTypes.string,
  iconMaxHeight: PropTypes.number,
  iconOpacity: PropTypes.string,
  iconOffset: PropTypes.string,
  disabled: PropTypes.bool,
  active: PropTypes.bool,
  block: PropTypes.bool
}

export default Button
