import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import gql from 'graphql-tag'
import { graphql } from 'react-apollo'

import Ionicon from '../Ionicon'
import Dropdown from './Dropdown'

const GET_STAGES = gql`
  query getStages {
    stages: getStages {
      id
      name
    }
  }
`

const StageDropdown = ({ data, ...dropdownProps }) => {
  const options = useMemo(() => {
    return data.stages && [ { value: { id: '' }, label: '\u00A0' }, ...data.stages.map( x => ( {
      value: x,
      label: x.name
    } ) ) ]
  }, [ data.stages ])
  return (
    <Dropdown
      options={options}
      valueIdKey='id'
      icon={<Ionicon name='growth' color='deepBlue' />}
      renderLabel={value => value.name}
      {...dropdownProps}
    />
  )
}

StageDropdown.propTypes = {
  data: PropTypes.object
}

export default graphql(GET_STAGES)(StageDropdown)
