import css from './BillingScreen.module.sass'

import React, { useState, useContext } from 'react'
import PropTypes from 'prop-types'
import gql from 'graphql-tag'
import { useMutation } from 'react-apollo'

import Alert from '../components/Alert'
import Checkout from '../components/Checkout'
import Coupon from '../components/Coupon'
import Layout from '../components/Layout'
import PaymentDetails from '../components/PaymentDetails'
import PlanDescription from '../components/PlanDescription'

import CurrentUserContext from '../components/CurrentUserContext'

import { gtmTrack } from '../helpers/tracking'
import {
  GTM_FOUNDER_CANCELED_PLAN,
  GTM_FOUNDER_UNCANCELED_PLAN,
  GTM_FOUNDER_SUBSCRIBED_TO_PLAN
} from '../constants/gtm'
import {
  START_PLAN_NAME, GROW_PLAN_NAME, SCALE_PLAN_NAME,
  START_PLAN_PRICE, GROW_PLAN_PRICE, SCALE_PLAN_PRICE
} from '../constants/plans'
import { SUBSCRIPTION_UPGRADED_PATH, SUBSCRIPTION_CANCELED_PATH } from '../constants/routes'
import { formatGraphQLError } from '../helpers/errors'
import { isActiveGrowPlan } from '../helpers/subscription'

const CANCEL_SUBSCRIPTION = gql`
  mutation cancelPaidPlanSubscription {
    cancelPaidPlanSubscription {
      code
      message
    }
  }
`

const UNCANCEL_SUBSCRIPTION = gql`
  mutation {
    unCancelPaidPlanSubscription {
      code
      message
    }
  }
`

const BillingScreen = ({ history }) => {
  const [ chosenPlan, setChosenPlan ] = useState()
  const [ coupon, setCoupon ] = useState()

  const [
    cancelSubscription,
    { loading: canceling, error: cancelingError }
  ] = useMutation(CANCEL_SUBSCRIPTION)

  const [
    uncancelSubscription,
    { loading: uncanceling, error: uncancelingError }
  ] = useMutation(UNCANCEL_SUBSCRIPTION)

  const { currentUser: { subscription }, onRefetch } = useContext(CurrentUserContext)

  return (
    <Layout variant='dashboard'>
      <Checkout>
        {({ checkout, subscribing, loading: checkoutLoading, error: checkoutError }) => {
          const error = cancelingError || uncancelingError || checkoutError
          const loading = canceling || subscribing || uncanceling || checkoutLoading

          const redirectToUpgraded = () => {
            onRefetch()
            history.push({
              pathname: SUBSCRIPTION_UPGRADED_PATH,
              state: { upgraded: true }
            })
          }

          const handleError = () => {
            setChosenPlan(null)
          }

          const buttonProps = {
            loading,
            chosenPlan,
            onCancel: (plan) => {
              setChosenPlan(plan)
              cancelSubscription()
                .then(() => {
                  gtmTrack(GTM_FOUNDER_CANCELED_PLAN, {
                    subscription: { plan }
                  })

                  onRefetch()
                  history.push({
                    pathname: SUBSCRIPTION_CANCELED_PATH,
                    state: { canceled: true }
                  })
                })
                .catch(handleError)
            },
            onUncancel: (plan) => {
              setChosenPlan(plan)
              uncancelSubscription()
                .then(() => {
                  gtmTrack(GTM_FOUNDER_UNCANCELED_PLAN, {
                    subscription: { plan }
                  })

                  redirectToUpgraded()
                })
                .catch(handleError)
            },
            onSubscribe: (description, amount, plan) => {
              setChosenPlan(plan)
              checkout({
                options: {
                  description,
                  amount
                },
                plan,
                coupon: coupon && coupon.id,
                onSuccess: () => {
                  gtmTrack(GTM_FOUNDER_SUBSCRIBED_TO_PLAN, {
                    subscription: { plan }
                  })

                  redirectToUpgraded()
                },
                onError: handleError
              })
            }
          }

          return (
            <section>
              {error &&
                <Alert variant='error' offset='double'>{formatGraphQLError(error)}</Alert>
              }

              {subscription && subscription.plan === START_PLAN_NAME &&
                <div className={css.coupon}>
                  <Coupon onUpdate={coupon => { setCoupon(coupon) }} />
                </div>
              }

              {isActiveGrowPlan(subscription) &&
                <PaymentDetails />
              }

              <PlanDescription
                plan={START_PLAN_NAME}
                name='Start'
                price={START_PLAN_PRICE}
                description='Basic tools to create a pitch video.'
                features={[
                  'Record up to 9 video topics using our template topics',
                  'Update pitch in real-time, any time',
                  'Unique URL to share your pitch with your network',
                  'Option to list your pitch in our investor portal and receive notifications of investor interest'
                ]}
              />

              <PlanDescription
                plan={GROW_PLAN_NAME}
                name='Grow'
                price={GROW_PLAN_PRICE}
                description='Enhanced tools to create and supplement a pitch video.'
                prevPlan={START_PLAN_NAME}
                features={[
                  'Customize your own unlimited video topics',
                  'Use of on-screen teleprompter as you record your pitch',
                  'Download your pitch for sharing, sending, or posting',
                  'Upload your pitch deck',
                  'Add team bios',
                  'Add key highlights',
                  'Get a meeting with investors who want to connect with you',
                  'View, email and chat with your investor connections on our platform',
                  'Track investor engagement analytics and receive real-time notifications',
                  'Utilize private dashboard to manage activity and interactions with investors',
                  'Access feedback on your pitch from investors'
                ]}
                buttonProps={
                  subscription && subscription.plan !== SCALE_PLAN_NAME
                    ? buttonProps
                    : void 0
                }
                coupon={coupon}
              />

              <PlanDescription
                plan={SCALE_PLAN_NAME}
                name='Scale'
                price={SCALE_PLAN_PRICE}
                description='Access premium benefits.'
                prevPlan={GROW_PLAN_NAME}
                features={[
                  '20 minute video chat with angel or VC investor',
                  '1-on-1 fundraising strategy session, including feedback on your ' +
                    'pitchtape',
                  'Customized pitch video with our production team',
                  'Assistance with pitch deck content and design',
                  'Access to startup roadmaps, form legal documents and other resources',
                  'Invite to curated startup events',
                  'Discounts to partner services (legal, financial, operational, etc.)',
                ]}
              />
            </section>
          )
        }}
      </Checkout>
    </Layout>
  )
}

BillingScreen.propTypes = {
  history: PropTypes.object
}

export default BillingScreen
