import css from './MobilePreventer.module.sass'

import React, { useContext } from 'react'

import Text from './Text'

import DevicesContext from './DevicesContext'

const MobilePreventer = () => {
  const { mobile } = useContext(DevicesContext)

  if (!mobile) {
    return null
  }

  return (
    <div className={css.container}>
      <Text tag='p' variant='standardLarger'>
        For the best experience please expand your browser window.
      </Text>
    </div>
  )
}

export default MobilePreventer
