import css from './index.module.sass'

import React, { useState, useRef } from 'react'
import gql from 'graphql-tag'
import { Mutation } from 'react-apollo'
import PropTypes from 'prop-types'

import Button from '../Button'
import Link from '../Link'
import Text from '../Text'
import Icon from '../Icon'
import Popup from '../Popup'
import ErrorModal from '../ErrorModal'
import { Consumer as CurrentUserConsumer } from '../CurrentUserContext'
import { Consumer as DevicesConsumer } from '../DevicesContext'

import { TOUR_ALREADY_COMPLETED_CODE } from '../../constants/codes'
import { GTM_PRODUCT_TOUR_COMPLETED } from '../../constants/gtm'
import { GET_ME } from '../../constants/queries'
import { isErrorCode } from '../../helpers/errors'
import { gtmTrack } from '../../helpers/tracking'

const USE_COMPLETED_TOUR_GUIDE = gql`
  mutation userCompletedTourGuide {
    userCompletedTourGuide {
      code
      message
    }
  }
`

const Tour = ({ steps }) => {
  const [ stepIndex, setStepIndex ] = useState(-1)

  const maxStepIndexRef = useRef(0)

  if (stepIndex > maxStepIndexRef.current) {
    maxStepIndexRef.current = stepIndex
  }

  return (
    <DevicesConsumer>
      {({ mobile }) =>
        <CurrentUserConsumer>
          {({ currentUser, refetching, onRefetch }) =>
            <Mutation
              update={(cache) => {
                const { me } = cache.readQuery({ query: GET_ME })

                cache.writeQuery({
                  query: GET_ME,
                  data: {
                    me: {
                      ...me,
                      completedTourGuide: true
                    }
                  }
                })
              }}
              mutation={USE_COMPLETED_TOUR_GUIDE}
              onCompleted={() => {
                if (maxStepIndexRef.current === steps.length - 1) {
                  gtmTrack(GTM_PRODUCT_TOUR_COMPLETED, {
                    user: { accountType: currentUser.accountType }
                  })
                }
              }}
              onError={(error) => {
                if (isErrorCode(error, TOUR_ALREADY_COMPLETED_CODE)) {
                  onRefetch()
                }
              }}
            >
              {(userCompletedTourGuide, { loading: completing, error: completingError }) => {
                const isIntro = stepIndex === -1
                const isLastStep = stepIndex === steps.length - 1

                const renderStep = (step) => {
                  return (
                    <Popup
                      visible
                      variant='tourModal'
                      overlay
                      anchorSelector={step.selector}
                      maxWidth={350}
                      header={
                        <div className={css.header}>
                          <Text tag='h1' variant='h3'>{step.title}</Text>

                          {!isIntro &&
                            <Text color='gray'>{stepIndex + 1}/{steps.length}</Text>
                          }
                        </div>
                      }
                      content={step.text}
                      footer={
                        <div className={css.footer}>
                          <div className={css.skip}>
                            {!isLastStep &&
                              <Link
                                disabled={completing}
                                onClick={() => userCompletedTourGuide()}
                              >
                                Skip Tour
                              </Link>
                            }
                          </div>

                          {!isIntro && (
                            mobile
                              ? (
                                <Button
                                  variant={mobile ? 'iconOutline' : 'outline'}
                                  offset={mobile ? void 0 : 'single-left'}
                                  onClick={() => setStepIndex(stepIndex - 1)}
                                >
                                  {mobile ? <Icon name='arrowLeftBlue' /> : 'Previous'}
                                </Button>
                              )
                              : (
                                <Link onClick={() => setStepIndex(stepIndex - 1)}>
                                  Previous
                                </Link>
                              )
                          )}

                          <Button
                            variant={mobile
                              ? (isIntro || isLastStep) ? 'primary' : 'iconPrimary'
                              : 'primaryNarrow'}
                            offset={mobile ? 'half-left' : 'single-left'}
                            disabled={completing || refetching}
                            onClick={() => {
                              if (isLastStep) {
                                userCompletedTourGuide()
                              } else {
                                setStepIndex(stepIndex + 1)
                              }
                            }}
                          >
                            {isIntro
                              ? 'Start Tour'
                              : isLastStep
                                ? 'Finish Tour'
                                : (mobile ? <Icon name='arrowRightWhite' /> : 'Next')
                            }
                          </Button>
                        </div>
                      }
                    />
                  )
                }

                return (
                  <>
                    {completingError && !isErrorCode(completingError, TOUR_ALREADY_COMPLETED_CODE) &&
                      <ErrorModal
                        error={completingError}
                        onRetry={() => userCompletedTourGuide()}
                      />
                    }

                    {isIntro
                      ? (
                        renderStep({
                          title: 'Let’s Get Started',
                          text: (
                            <>
                              We’ll run you through a quick product tour to get you familiar with the platform.
                              You can also check out our website{' '}
                              <Link external href='https://www.pitchtape.com'>pitchtape.com</Link> and{' '}
                              <Link external href='https://www.pitchtape.com/faq'>FAQs</Link> for more info.
                            </>
                          )
                        })
                      )
                      : renderStep(steps[stepIndex])
                    }
                  </>
                )
              }}
            </Mutation>
          }
        </CurrentUserConsumer>
      }
    </DevicesConsumer>
  )
}

Tour.propTypes = {
  steps: PropTypes.array
}

export default Tour
