import React from 'react'
import PropTypes from 'prop-types'
import { useQuery } from 'react-apollo'

import DocViewer from '../DocViewer'

import { GET_USER_COMPANY_MAIN } from '../../constants/queries'

const Slide = ({ deckPage }) => {
  const { data: companyData } = useQuery(GET_USER_COMPANY_MAIN)

  const slidesFile = companyData?.company.slidesFile

  if (!slidesFile) {
    return null
  }

  return (
    <DocViewer
      key={deckPage}
      variant='fillHeight'
      src={slidesFile}
      noFullscreen
      noControls
      defaultPage={deckPage}
    />
  )
}

Slide.propTypes = {
  deckPage: PropTypes.number
}

export default Slide
