import css from './Reviews.module.sass'

import React from 'react'
import PropTypes from 'prop-types'

import Link from '../Link'
import List from '../List'
import Loader from '../Loader'
import Text from '../Text'

import { formatDate } from '../../helpers/dates'

const Reviews = ({ loading, reviews, hasMore, onLoadMore }) => {
  if (loading) {
    return <Loader />
  }

  return (
    <>
      {!reviews || !reviews.length
        ? <Text tag='p' variant='standardLarger'>Not reviewed</Text>
        : (
          <List variant='withSeparator' gap='15'>
            {reviews.map(({ id, review, updatedAt }) =>
              <List.Item key={id}>
                <div className={css.row}>
                  <Text variant='standardLarger' color='gray'>{formatDate(updatedAt)}</Text>
                  <Text tag='p' variant='standardLarger'>{review}</Text>
                </div>
              </List.Item>
            )}
            {hasMore &&
              <Link variant='expander' onClick={onLoadMore}>
                Load more
              </Link>
            }
          </List>
        )
      }
    </>
  )
}

Reviews.propTypes = {
  loading: PropTypes.bool,
  reviews: PropTypes.array,
  hasMore: PropTypes.bool,
  onLoadMore: PropTypes.func
}

export default Reviews
