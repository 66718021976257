import React, { useMemo, useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'
import { withApollo } from 'react-apollo'

import List from '../List'
import Link from '../Link'
import ChatLayout from '../ChatLayout'
import ConnectionList from '../ConnectionList'
import Chat from '../Chat'
import Text from '../Text'
import NoCompanies from './NoCompanies'

import { ACTIVITY_PATH } from '../../constants/routes'
import { GET_CONNECTED_COMPANIES } from '../../constants/queries'
import { toWithParams } from '../../helpers/routes'

const InvestorConnections = ({
  client, type, selectedConnectionId,
  companiesData, loadingCompanies, loadingMoreCompanies, companiesError,
  companyData, loadingCompany, companyError,
  onFetchMoreCompanies
}) => {
  const companies = companiesData && companiesData.companies
  const connection = companyData && companyData.getConnectedCompany

  const handleFetchMore = useCallback(() => {
    return onFetchMoreCompanies({
      variables: {
        first: 10,
        after: companies.pageInfo.endCursor
      },
      updateQuery: (prev, { fetchMoreResult: next }) => {
        return {
          companies: {
            ...prev.companies,
            edges: [
              ...prev.companies.edges,
              ...next.companies.edges
            ],
            pageInfo: next.companies.pageInfo
          }
        }
      }
    })
  }, [ companies, onFetchMoreCompanies ])

  const connections = useMemo(() => companies && companies.edges.map(({ node: { id, company, chat } }) => ({
    id: id,
    companyId: company.id,
    selected: id === selectedConnectionId,
    label: company.name,
    sublabel: company.description,
    count: chat && chat.unreadMessages,
    ...(type === 'connected' &&
      { chatUrl: toWithParams(ACTIVITY_PATH, { connectionId: id, type }) }
    )
  })), [ companies, type, selectedConnectionId ])

  const chatConnection = useMemo(() => {
    if (!connection) {
      return
    }

    const { dateConnected, company: { name, description, createdBy } } = connection

    return {
      name: name,
      description: description,
      dateConnected: new Date(dateConnected),
      contact: `${createdBy.firstName} ${createdBy.lastName}`
    }
  }, [ connection ])

  useEffect(() => {
    if (!connection || loadingCompanies || type !== 'connected') {
      return
    }

    const { chat } = connection
    const unreadMessages = chat && chat.unreadMessages

    if (!unreadMessages) {
      return
    }

    const { companies } = client.readQuery({ query: GET_CONNECTED_COMPANIES })

    client.writeQuery({
      query: GET_CONNECTED_COMPANIES,
      data: {
        companies: {
          ...companies,
          totalUnreadMessages: companies.totalUnreadMessages - unreadMessages,
          edges: companies.edges.map(edge => {
            const { node } = edge

            if (connection.id === node.id) {
              return {
                ...edge,
                node: {
                  ...edge.node,
                  chat: {
                    ...edge.node.chat,
                    unreadMessages: 0
                  }
                }
              }
            }

            return edge
          })
        }
      }
    })
  }, [ client, connection, loadingCompanies, type ])

  return (
    <ChatLayout
      tabs={
        <List variant='horizontal'>
          <List.Item>
            <Link variant='tab' nav to={toWithParams(ACTIVITY_PATH, { type: 'connected' })}>
              Connected
            </Link>
          </List.Item>
          <List.Item>
            <Link variant='tab' nav to={toWithParams(ACTIVITY_PATH, { type: 'saved' })}>
              Saved
            </Link>
          </List.Item>
        </List>
      }
      listLabel='Startups'
      list={
        <ConnectionList
          connections={connections}
          loading={loadingCompanies}
          loadingMore={loadingMoreCompanies}
          error={companiesError}
          selectedId={selectedConnectionId}
          noConnectionsMessage={`
            Startups that you ${type === 'saved' ? 'save' : 'connect with'} in your feed will be listed here.
          `}
          onFetchMore={companies && companies.pageInfo.hasNextPage ? handleFetchMore : void 0}
        />
      }
      connectionSelected={Boolean(selectedConnectionId)}
      hasConnections={connections && Boolean(connections.length)}
      chat={
        connections && (
          type === 'connected' && connections.length
            ? (
              <Chat
                connection={chatConnection}
                loadingConnection={loadingCompany}
                connectionError={companyError}
                connectionId={selectedConnectionId}
                emptyMessage={chatConnection &&
                  <>
                    <Text tag='p'>
                      You have connected with {chatConnection.contact} from {chatConnection.name}.
                    </Text>
                    <Text tag='p'>Type your message below to start a private conversation.</Text>
                  </>
                }
                noChatMessage={
                  'Select a startup from your list of connections to ' +
                  'start or respond to a private conversation.'
                }
                tallHeader
              />
            )
            : <NoCompanies type={type} />
        )
      }
      noMessagesLabel={type === 'saved'}
      companyId={connection && connection.company.id}
      backUrl={toWithParams(ACTIVITY_PATH, { type })}
    />
  )
}

InvestorConnections.propTypes = {
  client: PropTypes.object,
  type: PropTypes.string,
  companiesData: PropTypes.object,
  loadingCompanies: PropTypes.bool,
  loadingMoreCompanies: PropTypes.bool,
  companiesError: PropTypes.object,
  companyData: PropTypes.object,
  loadingCompany: PropTypes.bool,
  companyError: PropTypes.object,
  selectedConnectionId: PropTypes.string,
  onFetchMoreCompanies: PropTypes.func
}

export default withApollo(InvestorConnections)
