import React from "react"
import PropTypes from "prop-types"
import Modal from "../Modal"
import Button from "../Button"
import Text from "../Text"
import Ionicon from "../Ionicon"
import DevicesDropdown from "./DevicesDropdown"
import css from './SettingsModal.module.sass'

const SettingsModal = ({ audioDeviceId, setAudioDeviceId, videoDeviceId, setVideoDeviceId, onCancel }) => {
    return (
        <Modal
            variant="filter"
            fullFooter
            noButtons
            title={
                <>
                    <Text tag="h1" variant="h2" color="white">
                        Recording Settings
                    </Text>
                    <Button className="tw-absolute tw-top-1 tw-right-1" variant="icon" onClick={onCancel}>
                        <Ionicon name="close" size="32" color="white" />
                    </Button>
                </>
            }
        >
            <div className={css.settingsModal}>
                <div>
                    <DevicesDropdown
                        label="Microphone *"
                        name="audio"
                        value={audioDeviceId}
                        required={true}
                        onUpdate={({ audio: { deviceId } }) => setAudioDeviceId(deviceId)}
                    />
                </div>

                <div>
                    <DevicesDropdown
                        label="Camera *"
                        name="video"
                        value={videoDeviceId}
                        required={true}
                        onUpdate={({ video: { deviceId } }) => setVideoDeviceId(deviceId)}
                    />
                </div>
            </div>
        </Modal>
    )
}

SettingsModal.propTypes = {
    audioDeviceId: PropTypes.any,
    setAudioDeviceId: PropTypes.func,
    videoDeviceId: PropTypes.any,
    setVideoDeviceId: PropTypes.func,
    onCancel: PropTypes.func,
}

export default SettingsModal
