import React, {useRef, useState} from 'react'
import Popup from "../Popup";
import css from "./index.module.sass";
import Text from "../Text";
import Link from "../Link";
import Button from "../Button";
import Icon from "../Icon";
import PropTypes from "prop-types";
import {CREATE_VIDEOS_GENERIC_PATH} from "../../constants/routes";
import {injectParams} from "../../helpers/routes";
import {useHistory} from "react-router-dom";
import gql from "graphql-tag";
import {useMutation} from "react-apollo";
import {GET_ME} from "../../constants/queries";
import Loader from "../Loader";
import {formatGraphQLError} from "../../helpers/errors";
import Alert from "../Alert";

const USER_COMPLETED_ONBOARDING_PROFILE = gql`
  mutation {
    userCompletedProfileOnboarding {
        code
        message
    }
  }
`


const OnBoarding = ({steps, refetch}) => {
    const history = useHistory()
    const [ stepIndex, setStepIndex ] = useState(0)
    const [ visible, setVisible ] = useState(true)
    const maxStepIndexRef = useRef(0)
    if (stepIndex > maxStepIndexRef.current) {
        maxStepIndexRef.current = stepIndex
    }
    const mobile = false

    const isFirstStep = stepIndex === 0
    const isLastStep = stepIndex === steps.length - 1

    const exitOnboarding = () =>{
        setVisible(false)
        if(refetch)
            refetch()
        if(isLastStep){
            history.push({
                pathname: CREATE_VIDEOS_GENERIC_PATH,
                state: { firstTimeInVideoBuilder: true }
            })
            history.replace(
                injectParams(CREATE_VIDEOS_GENERIC_PATH )
            )
        }
    }
    const [ setCompletedOnboardingProfile, { loading: completing, error } ] = useMutation(
        USER_COMPLETED_ONBOARDING_PROFILE, {
        update: (cache) => {
            const { me } = cache.readQuery({ query: GET_ME })

            cache.writeQuery({
                query: GET_ME,
                data: {
                    me: {
                        ...me,
                        completedOnboardingProfile: true
                    }
                }
            })
        },
        onCompleted: () =>{
            exitOnboarding()
        },
        onError: () =>{
            console.log(error)
            exitOnboarding()
        }
    })

    const setGuideCompleted = () =>{
        setCompletedOnboardingProfile()
    }

    const renderStep = (step) => {
        return (
            <Popup
                visible={visible}
                variant='founderOnboarding'
                overlay
                maxWidth={850}
                header={
                    <div className={css.header}>
                        <Text tag='h1' variant='h3' color='light-blue'>{step.title}</Text>
                        <Text color='gray'>{stepIndex + 1}/{steps.length}</Text>
                    </div>
                }
                content={(completing ? <Loader variant='centered' /> : (
                    error ? <Alert variant='error'>{ formatGraphQLError( error ) }</Alert> : step.text))}
                footer={
                    <div className={css.footer}>
                        <div className={css.skip}>
                            {!isLastStep &&
                            <Link
                                onClick={() => setGuideCompleted()}
                            >
                                Exit
                            </Link>
                            }
                        </div>

                        {!isFirstStep && (
                            mobile
                                ? (
                                    <Button
                                        variant={mobile ? 'iconOutline' : 'outline'}
                                        offset={mobile ? void 0 : 'single-left'}
                                        onClick={() => setStepIndex(stepIndex - 1)}
                                    >
                                        {mobile ? <Icon name='arrowLeftBlue' /> : 'Previous'}
                                    </Button>
                                )
                                : (
                                    <Link onClick={() => setStepIndex(stepIndex - 1)}>
                                        Previous
                                    </Link>
                                )
                        )}

                        <Button
                            variant={mobile
                                ? isLastStep ? 'primary' : 'iconPrimary'
                                : 'primaryNarrow'}
                            offset={mobile ? 'half-left' : 'single-left'}
                            onClick={() => {
                                if (isLastStep) {
                                    setGuideCompleted()
                                } else {
                                    setStepIndex(stepIndex + 1)
                                }
                            }}
                        >
                            {isLastStep
                                    ? `You're all set!`
                                    : (mobile ? <Icon name='arrowRightWhite' /> : 'Next')
                            }
                        </Button>
                    </div>
                }
            />
        )
    }

    return (renderStep(steps[stepIndex]))
}

OnBoarding.propTypes = {
    steps: PropTypes.array,
    refetch: PropTypes.func
}

export default OnBoarding