import React from 'react'
import PropTypes from 'prop-types'
import { Redirect } from 'react-router-dom'

import Layout from '../components/Layout'
import Text from '../components/Text'
import Button from '../components/Button'

import { SURVEY_URL } from '../constants/urls'
import { PITCHTAPE_PATH } from '../constants/routes'

const SubscriptionCanceledScreen = ({ location }) => {
  if (!location.state || !location.state.canceled) {
    return <Redirect to={PITCHTAPE_PATH} />
  }

  return (
    <Layout variant='dashboard'>
      <Text tag='h1' variant='h2' offset='half-bottom'>
        You have canceled the Grow plan.
      </Text>

      <Text tag='p' variant='large' offset='double-bottom' style={{ maxWidth: '620px' }}>
        You will be automatically enrolled in the free Start plan at the end of the billing cycle.
        If you have already submitted your pitchtape to our investor portal,
        your pitchtape will remain visible to investors in our network.
      </Text>

      <Text tag='p' variant='large' offset='double-bottom' style={{ maxWidth: '620px' }}>
        Please take a 1-minute survey to let us know what made you cancel your Grow subscription today.
      </Text>

      <Button variant='primary' external href={SURVEY_URL}>
        Take survey
      </Button>
    </Layout>
  )
}

SubscriptionCanceledScreen.propTypes = {
  location: PropTypes.object
}

export default SubscriptionCanceledScreen
