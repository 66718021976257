import css from './Well.module.sass'

import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import SectionTitle from './SectionTitle'

let Well = ( { title, nextToTitle, description, highlight, highlightTitle, noMargin, padding, children }, ref ) => {
  const SectionTag = title ? 'section' : 'div'

  const renderContent = () =>
    <>
      { ( title || description ) &&
      <SectionTitle
        title={ title }
        nextToTitle={ nextToTitle }
        description={ description }
        highlight={ highlightTitle }
      />
      }
      {children}
    </>

  return (
    <SectionTag
      className={classNames(css.container, css[`padding-${padding}`], {
        [css.noMargin]: noMargin,
        [css.highlight]: highlight
      })}
      ref={ref}
    >
      {highlight
        ? (
          <div className={css.highlightInner}>
            {renderContent()}
          </div>
        )
        : renderContent()
      }
    </SectionTag>
  )
}

Well = forwardRef(Well)

Well.propTypes = {
  title: PropTypes.node,
  nextToTitle: PropTypes.node,
  description: PropTypes.node,
  highlight: PropTypes.bool,
  highlightTitle: PropTypes.bool,
  padding: PropTypes.string,
  noMargin: PropTypes.bool,
  children: PropTypes.node
}

export default Well
