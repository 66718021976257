import css from './DeckManager.module.sass'

import React, { useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import { useMutation, useQuery } from 'react-apollo'
import gql from 'graphql-tag'

import Alert from './Alert'
import Button from './Button'
import DocViewer from './DocViewer'
import { FileField } from './Form'
import Ionicon from './Ionicon'
import Loader from './Loader'

import { GET_USER_COMPANY_MAIN } from '../constants/queries'
import { formatGraphQLError } from '../helpers/errors'

const UPLOAD_COMPANY_SLIDES = gql`
  mutation($slidesFile: Upload) {
    uploadCompanySlides(input: { slidesFile: $slidesFile }) {
      obj {
        slidesFile
      }
      errors {
        field
        messages
      }
    }
  }
`

const ACCEPTED_FILE_TYPES = [ '.pdf', 'application/pdf' ].join(',')

const DeckManager = ({ header, placeholder, defaultPage, selectedPage, renderFooter, disabled }) => {
  const [ page, setPage ] = useState(1)

  const { data: companyData, loading: fetchingCompany } = useQuery(GET_USER_COMPANY_MAIN)

  const [ uploadCompanySlides, { data, loading: uploading, error } ] = useMutation(UPLOAD_COMPANY_SLIDES, {
    update: (cache, { data: { uploadCompanySlides: { obj, errors } } }) => {
      if (errors) {
        return
      }

      const { company } = cache.readQuery({ query: GET_USER_COMPANY_MAIN })

      cache.writeQuery({
        query: GET_USER_COMPANY_MAIN,
        data: {
          company: {
            ...company,
            slidesFile: obj.slidesFile
          }
        }
      })
    }
  })

  const handlePageChange = useCallback((_page) => {
    setPage(_page)
  }, [])

  if (fetchingCompany) {
    return <Loader />
  }

  const slidesFile = companyData?.company.slidesFile

  return (
    <section>
      {header}

      {data && data.errors &&
        <Alert variant='error'>{data.errors[0].messages.join('')}</Alert>
      }

      {error &&
        <Alert variant='error'>{formatGraphQLError(error)}</Alert>
      }

      {slidesFile
        ? (
          <>
            <Button
              variant='primary'
              disabled={uploading}
              icon={<Ionicon name='trash' size='24' color='white' />}
              onClick={() => {
                uploadCompanySlides({
                  variables: {
                    slidesFile: null
                  }
                })
              }}
            >
              Delete file
            </Button>

            <div className={css.viewer}>
              <DocViewer
                src={slidesFile}
                noFullscreen
                defaultPage={defaultPage}
                selectedPage={selectedPage}
                onPageChange={handlePageChange}
              />
            </div>

            {renderFooter && renderFooter({ page })}
          </>
        )
        : (
          <>
            <FileField
              accept={ACCEPTED_FILE_TYPES}
              onChange={(file) => {
                uploadCompanySlides({
                  variables: {
                    slidesFile: file
                  }
                })
              }}
            >
              {({ chooseFile }) =>
                <Button
                  disabled={uploading || disabled}
                  variant='primary'
                  icon={<Ionicon name='cloudUpload' color='white' size='32' />}
                  onClick={chooseFile}
                >
                  Upload file
                </Button>
              }
            </FileField>

            {placeholder &&
              <div className={css.placeholder}>{placeholder}</div>
            }
          </>
        )
      }
    </section>
  )
}

DeckManager.propTypes = {
  header: PropTypes.node,
  placeholder: PropTypes.node,
  defaultPage: PropTypes.number,
  selectedPage: PropTypes.number,
  renderFooter: PropTypes.func,
  disabled: PropTypes.bool
}

export default DeckManager
