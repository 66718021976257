import css from "./index.module.sass"

import React, {
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
  useCallback,
  useContext,
} from "react"
import PropTypes from "prop-types"
import { withRouter } from "react-router-dom"

import Header from "./Header"
import Video from "./Video"
import Deck from "./Deck"
import Highlights from "./Highlights"
import Team from "./Team"
import CompanyDetails from "./CompanyDetails"
import Footer from "./Footer"
import VideoNotReady from "../VideoNotReady"

import DevicesContext from "../DevicesContext"

import { HEADER_CLASSNAME } from "../../constants/classnames"
import { VIDEO_FINISHED_STATUS } from "../../constants/videos"
import { getBounds } from "../../helpers/ui"
import SectionHeading from "./SectionHeading"

const PAGE_POSSIBLE_EXTRA_WIDTH = 150

const PitchtapeReview = ({
  company,
  editable,
  shareable,
  submitButton,
  forInvestor,
  onInvestorAction,
  onRate,
  location,
}) => {
  const [activeIndex, setActiveIndex] = useState(0)
  const [transparentHeader, setTransparentHeader] = useState()
  const [sectionHeight, setSectionHeight] = useState()
  const [videoHeight, setVideoHeight] = useState()
  const [pageMaxWidth, setPageMaxWidth] = useState()

  const { mobile, mobileUa } = useContext(DevicesContext)

  const { concatenatedVideo } = company
  const hasFinishedVideo =
    concatenatedVideo && concatenatedVideo.status === VIDEO_FINISHED_STATUS

  const videoRef = useRef()
  const deckRef = useRef()
  const highlightsRef = useRef()
  const teamRef = useRef()
  const headerRef = useRef()
  const headerHeightRef = useRef()
  const footerRef = useRef()
  const footerHeightRef = useRef()
  const mainNavHeightRef = useRef()
  const sectionsRef = useRef([videoRef, deckRef, highlightsRef, teamRef])

  useLayoutEffect(() => {
    if (mobile) {
      // setVideoHeight()
      setSectionHeight()
      setTransparentHeader()
      return
    }

    const handleResize = () => {
      const mainNavHeight = document.querySelector(`.${HEADER_CLASSNAME}`).clientHeight
      mainNavHeightRef.current = mainNavHeight

      if (!mobileUa) {
        const footerHeight = footerRef.current.clientHeight
        const headerHeight = headerRef.current.clientHeight

        footerHeightRef.current = footerHeight
        headerHeightRef.current = headerHeight

        const pageInnerHeight = window.innerHeight - mainNavHeight - footerHeight
        setSectionHeight(pageInnerHeight - headerHeightRef.current)

        setPageMaxWidth(Math.floor(pageInnerHeight / 9 * 16) + PAGE_POSSIBLE_EXTRA_WIDTH)
        setVideoHeight(pageInnerHeight)
      } else {
        setSectionHeight(320)
      }

      handleScroll()
    }

    const handleScroll = () => {
      const sections = sectionsRef.current
      const mainNavHeight = mainNavHeightRef.current
      const headerHeight = headerHeightRef.current
      const footerHeight = footerHeightRef.current

      const pageInnerHeight = window.innerHeight - mainNavHeight - footerHeight
      const sectionThreshold = Math.floor((pageInnerHeight - headerHeight) / 2)

      const windowScrollTop = window.scrollY + mainNavHeight + headerHeight

      const visibleSections = sections
        .map((s) => s.current)
        .filter((s) => Boolean(s))

      const activeSection = visibleSections.find((section, index) => {
        if (index === visibleSections.length - 1) {
          return true
        }

        const top = getBounds(section).top - sectionThreshold
        const nextTop =
          getBounds(visibleSections[index + 1]).top - sectionThreshold

        return (
          (index === 0 && windowScrollTop < top) ||
          (windowScrollTop >= top && windowScrollTop < nextTop)
        )
      })

      const nextActiveIndex = sections.findIndex(
        (s) => s.current === activeSection
      )

      if (activeIndex !== nextActiveIndex) {
        setActiveIndex(nextActiveIndex)
      }

      const headerShouldBeTransparent =
        windowScrollTop < pageInnerHeight + mainNavHeight

      if (!transparentHeader && headerShouldBeTransparent) {
        setTransparentHeader(true)
      } else if (transparentHeader && !headerShouldBeTransparent) {
        setTransparentHeader(false)
      }
    }

    if (transparentHeader === void 0) {
      handleResize()
    }

    window.addEventListener("resize", handleResize)
    window.addEventListener("scroll", handleScroll)

    return () => {
      window.removeEventListener("resize", handleResize)
      window.removeEventListener("scroll", handleScroll)
    }
  }, [activeIndex, transparentHeader, mobile, mobileUa])

  const handleSectionSelect = useCallback((index) => {
    const sections = sectionsRef.current
    const section = sections[index].current

    const { top } = getBounds(section)

    window.scrollTo(
      0,
      top - mainNavHeightRef.current - headerHeightRef.current
    )
  }, [])

  useEffect(() => {
    const idToIndex = {
      video: 0,
      deck: 1,
      highlights: 2,
      team: 3,
    }

    if (location.hash) {
      const index = idToIndex[location.hash.slice(1)]

      if (index != null) {
        setTimeout(function () {
          handleSectionSelect(index)
        }, 0)
      }
    }
  }, [handleSectionSelect, location])

  const renderHeader = (variant = "base", ref) => {
    return (
      <Header
        variant={variant}
        company={company}
        shareable={shareable}
        editable={editable}
        submitButton={submitButton}
        forInvestor={forInvestor}
        pageMaxWidth={pageMaxWidth}
        onInvestorAction={onInvestorAction}
        onRate={onRate}
        ref={ref}
      />
    )
  }

  return (
    <article className={css.container} style={{ maxWidth: pageMaxWidth }}>
      {(mobile || !transparentHeader) && renderHeader()}

      <div ref={videoRef}>
        {hasFinishedVideo ? (
          <Video
            header={!mobileUa && renderHeader("transparent", headerRef)}
            company={company}
            height={videoHeight}
          />
        ) : (
          <div className={css.noVideo} style={{ height: videoHeight }}>
            {!mobile && (
              <div className={css.noVideoHeader}>
                {renderHeader("transparent", headerRef)}
              </div>
            )}

            <VideoNotReady company={company} />
          </div>
        )}
      </div>

      {(mobile || mobileUa) && (
        <div className={css.mobileCompanyDetails}>
          <CompanyDetails
            forInvestor={forInvestor}
            company={company}
            iconColor="black"
            onRate={onRate}
          />
        </div>
      )}

      <div className={css.feedSections}>
        {company.slidesFile && (mobile || sectionHeight) && (
          <div className={`${css.containerDeck} ${css.feedSection}`}>
            <section className={css.contentDeck}>
              <SectionHeading icon="deck_black">DECK</SectionHeading>
              <div ref={deckRef}>
                <Deck company={company} height={sectionHeight} />
              </div>
            </section>
          </div>
        )}
      </div>
      <div className={css.feedSections}>
        {company.highlights && company.isPaidSubscriptionActive && (
          <div ref={highlightsRef} className={css.feedSection}>
            <Highlights company={company} />
          </div>
        )}
      </div>

      <div className={css.feedSections}>
        {company.isPaidSubscriptionActive &&
          Boolean(company.members && company.members.length) && (
            <div ref={teamRef} className={css.feedSection}>
              <Team company={company} />
            </div>
          )}
      </div>

      {!mobile && !mobileUa && (
        <Footer
          noDeck={!company.isPaidSubscriptionActive || !company.slidesFile}
          noHighlights={
            !company.isPaidSubscriptionActive || !company.highlights
          }
          noTeam={
            !company.isPaidSubscriptionActive ||
            !company.members ||
            !company.members.length
          }
          activeIndex={activeIndex}
          onSectionSelect={handleSectionSelect}
          ref={footerRef}
        />
      )}
    </article>
  )
}

PitchtapeReview.propTypes = {
  company: PropTypes.object,
  editable: PropTypes.bool,
  shareable: PropTypes.bool,
  submitButton: PropTypes.node,
  forInvestor: PropTypes.bool,
  onInvestorAction: PropTypes.func,
  onRate: PropTypes.func,
  location: PropTypes.object,
}

export default withRouter(PitchtapeReview)
