import React from 'react'
import PropTypes from 'prop-types'

import VideoPlayer from '../VideoPlayer'

const Video = ({ header, company, height }) => {
  if (!company.concatenatedVideo) {
    return null
  }

  return (
    <div style={{ height }}>
      <VideoPlayer
        variant={height ? 'fill' : 'base'}
        header={header}
        topics={height ? company.videoTopics : null} // don't render slide until height is known
        binary={company.concatenatedVideo.binary}
        hlsReady={false}
      />
    </div>
  )
}

Video.propTypes = {
  header: PropTypes.node,
  company: PropTypes.object,
  height: PropTypes.number
}

export default Video
