import css from './PrivacyBlock.module.sass'

import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import { useMutation } from 'react-apollo'

import Well from '../Well'

import { PUBLISH_COMPANY, UNPUBLISH_COMPANY } from '../../constants/queries'

import { GTM_PITCHTAPE_PUBLISHED_EVENT } from '../../constants/gtm'
import { gtmTrack } from '../../helpers/tracking'
import { updateCompanyCachePublishDate } from "./cacheActions"
import { VIDEO_FINISHED_STATUS } from "../../constants/videos"
import SubmitToFeedModal from "./SubmitToFeedModal"
import Button from "../Button"
import SubmittedModal from "./SubmittedModal"
import RemoveFromFeedModal from "./RemoveFromFeedModal"
import RemovedFromFeedModal from "./RemovedFromFeedModal"
import AnimatedEllipsis from "../AnimatedEllipsis"
import Icon from "../Icon"
import Text from "../Text";
const PRODUCT_NAME = 'Pitch'


const PrivacyBlock = ({ company }) => {
  const [ publishCompany, {loading: publishing} ] = useMutation(PUBLISH_COMPANY, {
    update: (cache, {data: {publishCompany: {errors}}}) => {
      if (errors) {
        return
      }

      updateCompanyCachePublishDate(cache, (new Date()).toISOString())
    },
    onCompleted: () => {
      gtmTrack(GTM_PITCHTAPE_PUBLISHED_EVENT, {
        pitchtape: {id: company.id}
      })
      // Close the confirm Modal
      if (openConfirmModal)
        toggleConfirmModal()
      // Open the Congrats Modal
      if (!openCongratsModal)
        toggleCongratsModal()
    }
  })

  const [ unpublishCompany, {loading: unpublishing} ] = useMutation(UNPUBLISH_COMPANY, {
    update: (cache, {data: {unpublishCompany: {errors}}}) => {
      if (errors) {
        return
      }

      updateCompanyCachePublishDate(cache, null)
    },
    onCompleted: () => {
      // Close the confirm remove from feed Modal
      if (openRemoveFromFeedModal)
        toggleRemoveFromFeedModal()
      // Open the verify deleted Modal
      toggleRemovedFromFeedModal()
    }
  })

  const handleSubmitClick = useCallback(() => {
    if (!publishing) {
      publishCompany()
    }
  }, [ publishCompany, publishing ])

  const handleUnpublishClick = useCallback(() => {
    if (!unpublishing) {
      unpublishCompany()
    }
  }, [ unpublishCompany, unpublishing ])

  const [ openConfirmModal, setOpenConfirmModal ] = useState(false)
  const [ openCongratsModal, setOpenCongratsModal ] = useState(false)
  const [ openRemoveFromFeedModal, setOpenRemoveFromFeedModal ] = useState(false)
  const [ openRemovedFromFeedModal, setOpenRemovedFromFeedModal ] = useState(false)

  const toggleRemoveFromFeedModal = (() => {
    setOpenRemoveFromFeedModal(!openRemoveFromFeedModal)
  })

  const toggleConfirmModal = (() => {
    setOpenConfirmModal(!openConfirmModal)
  })

  const toggleCongratsModal = (() => {
    if (openConfirmModal)
      toggleConfirmModal()
    setOpenCongratsModal(!openCongratsModal)
  })

  const toggleRemovedFromFeedModal = (() => {
    if (openRemoveFromFeedModal)
      toggleRemoveFromFeedModal()
    setOpenRemovedFromFeedModal(!openRemovedFromFeedModal)
  })

  const published = Boolean(company.publishedAt)
  const isDraft = Boolean(company.draft)
  const concatenatedVideoIsReady = company.concatenatedVideo?.status === VIDEO_FINISHED_STATUS

  return (
    <>

      {openConfirmModal && <SubmitToFeedModal
        buttons={<>
          <Button variant='outline' onClick={toggleConfirmModal}>
            Cancel
          </Button>
          <Button variant='primary' onClick={handleSubmitClick}>Submit</Button>
        </>}
        visible={openConfirmModal}
        loading={publishing}
      />}
      {openCongratsModal
      && <SubmittedModal buttons={<Button variant='primary' onClick={toggleCongratsModal}>Done</Button>}
                         visible={openCongratsModal}>
      </SubmittedModal>}

      {openRemoveFromFeedModal &&
      <RemoveFromFeedModal
        buttons={<>
          <Button variant='outline' onClick={toggleRemoveFromFeedModal}>
            Cancel
          </Button>
          <Button variant='primary' onClick={handleUnpublishClick}>Remove</Button>
        </>}
        visible={openRemoveFromFeedModal}
        loading={unpublishing}/>}

      {openRemovedFromFeedModal &&
      <RemovedFromFeedModal
        buttons={<Button variant='primary' onClick={toggleRemovedFromFeedModal}>Done</Button>}
        visible={openRemovedFromFeedModal}
      />}


      <Well
        title={`Submit ${PRODUCT_NAME}`}
      >
        <Text variant='standard' offset='double-bottom'>
          Investors will be able to view your pitch in our private investor portal and make a connection.
        </Text>
        <Button className={css.SubmitButton}
          variant='primary'
          disabled={publishing || published || isDraft || !concatenatedVideoIsReady}
          offset='double-top'
          onClick={toggleConfirmModal}>
          {published
            ? `${PRODUCT_NAME} Submitted`
            : publishing
              ? <>Submitting {PRODUCT_NAME}<AnimatedEllipsis/></>
              : `Submit ${PRODUCT_NAME}`
          }
        </Button>
        {published &&
        <Button variant='outline' className={css.RemovePitchFromFeed} onClick={toggleRemoveFromFeedModal}
                icon={<Icon name='xRed' opacity='50' style={{
                  'borderRadius': '50%', 'border': '1.5px solid red', 'padding': '2px'
                }}/>}>
          Remove {PRODUCT_NAME.toLowerCase()} from feed
        </Button>

        }
      </Well>
    </>
  )

}

PrivacyBlock.propTypes = {
  company: PropTypes.object
}

export default PrivacyBlock
