import React from "react";
import {
    CustomerCategoryDropdown,
    FormCol,
    FormRow,
    IndustryDropdown,
    LocationField,
    StageDropdown,
    TextField
} from "../Form";
import PropTypes from "prop-types";
import css from "./index.module.sass";
import Text from "../Text";


const CompanyForm = ({
        formData, saving, onUpdate, onInvalid, nameField, descriptionField, industryField, stageField,
        customerCategoryField, locationsField
    }) => {
    return (<>
        <div className={css.companyForm}>
            <div className={saving ? css.savingActive : css.saving}><Text variant='small'>saving...</Text></div>
            {nameField &&
            <FormRow>
                <FormCol size='half'>
                    <TextField
                        label='Company Name'
                        name='name'
                        placeholder='Your company name'
                        {...formData.name}
                        onUpdate={onUpdate}
                        onInvalid={onInvalid}
                    />
                </FormCol>
            </FormRow>
            }

            {descriptionField &&
            <FormRow offset='25'>
                <FormCol>
                    <TextField
                        label='Tagline'
                        name='description'
                        {...formData.description}
                        placeholder='Describe your company in one sentence'
                        maxlength={75}
                        counter
                        onUpdate={onUpdate}
                        onInvalid={onInvalid}
                    />
                </FormCol>
            </FormRow>
            }

            {industryField &&
            <FormRow offset='25'>
                <FormCol>
                    <IndustryDropdown
                        label='Industry'
                        name='industries'
                        {...formData.industries}
                        multiple
                        maxlength={3}
                        onUpdate={onUpdate}
                        onInvalid={onInvalid}
                    />
                </FormCol>
            </FormRow>
            }
            {stageField &&
            <FormRow offset='25'>
                <FormCol>
                    <StageDropdown
                        label='Stage'
                        info={
                            'Use the following ranges for the amount of funds you’re looking to raise ' +
                            'as general guidance to help choose the stage. Angel (<$1M). ' +
                            'Seed ($1M-$5M). Series A ($5M-$10M). Series B+ (>$10M).'
                        }
                        name='stage'
                        {...formData.stage}
                        onUpdate={onUpdate}
                        onInvalid={onInvalid}
                    />
                </FormCol>
            </FormRow>
            }
            {customerCategoryField &&
            <FormRow offset='25'>
                <FormCol>
                    <CustomerCategoryDropdown
                        label='Customer Category'
                        info={
                            'Consumer (or B2C) means that your primary customers are individuals. ' +
                            'Enterprise (or B2B) means that your primary customers are businesses.'
                        }
                        name='customerCategory'
                        {...formData.customerCategory}
                        onUpdate={onUpdate}
                        onInvalid={onInvalid}
                    />
                </FormCol>
            </FormRow>
            }
            {locationsField &&
            <FormRow offset='25'>
                <FormCol>
                    <LocationField
                        label='Location'
                        sublabel='Search and select city'
                        name='locations'
                        placeholder='Start typing city, then select location from dropdown'
                        {...formData.locations}
                        onUpdate={onUpdate}
                        onInvalid={onInvalid}
                    />
                </FormCol>
            </FormRow>
            }



        </div>
    </>)
}

CompanyForm.propTypes = {
    formData: PropTypes.object,
    saving: PropTypes.bool,
    onUpdate: PropTypes.func,
    onInvalid: PropTypes.func,
    nameField: PropTypes.bool,
    descriptionField: PropTypes.bool,
    industryField: PropTypes.bool,
    stageField: PropTypes.bool,
    customerCategoryField: PropTypes.bool,
    locationsField: PropTypes.bool
}

export default CompanyForm