import { REQUIRED_PITCHTAPE_FIELDS } from '../constants/forms'
import {VIDEO_FINISHED_STATUS} from '../constants/videos'
import { isAllPresent } from './objects'

export const isCompanyShareable = (company) => {
  return Boolean( company.concatenatedVideo?.status === VIDEO_FINISHED_STATUS )
}

export const isCompanyComplete = (company) => {
  return areCompanyDetailsComplete(company) && Boolean(company.concatenatedVideo)
}

export const areCompanyDetailsComplete = (company) => {
  return isAllPresent(company, REQUIRED_PITCHTAPE_FIELDS)
}

export const hasRequiredVideos = (company) => company.videoTopics.length


export const companyHasVideoInAnyTopic = (company) => {
  if(!company.videoTopics.length > 0){
    return false
  }else{
    for(let topic of company.videoTopics){
      if(topic.video){
        return true
      }
    }
    return false
  }
}


export const companyRequiresStitchVideos = (company) => {
  if(company.concatenatedVideo){
    return !(company.concatenatedVideo.status !== VIDEO_FINISHED_STATUS || company.concatenatedVideo.videoIsUpToDate)
  }else{
    return companyHasVideoInAnyTopic(company)
  }
}