import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import gql from 'graphql-tag'
import { graphql } from 'react-apollo'

import Layout from '../components/Layout'
import Loader from '../components/Loader'
import ErrorModal from '../components/ErrorModal'
import Button from '../components/Button'
import Text from '../components/Text'

import { SIGNIN_PATH } from '../constants/routes'
import { EMAIL_CONFIRMED_CODE, EMAIL_ALREADY_CONFIRMED_CODE } from '../constants/codes'
import { isErrorCode } from '../helpers/errors'

const CONFIRM_EMAIL = gql`
  mutation confirmEmail ($token: String!, $email: String!) {
    confirmEmail(token: $token, email: $email) {
      code
      message
    }
  }
`

const ConfirmScreen = ({ history, mutate, location }) => {
  const query = new URLSearchParams( location.search )
  const token = query.get( 'verification_token' )
  const email = query.get( 'email' )

  const [ error, setError ] = useState()
  const [ success, setSuccess ] = useState()

  useEffect( () => {
    if ( !token || !email ) {
      history.push( SIGNIN_PATH )
    } else {
      mutate( {
        variables: { token, email }
      } )
        .then( response => {
          const { data } = response
          if (data && data.confirmEmail.code === EMAIL_CONFIRMED_CODE) {
            setSuccess(true)
          } else {
            setError('Something went wrong')
          }
        })
        .catch(err => setError(err))
    }
  }, [ email, token, history, mutate ])

  return (
    <Layout variant='dashboard'>
      {!error && !success && <Loader />}

      {error && (
        isErrorCode(error, EMAIL_ALREADY_CONFIRMED_CODE)
          ? (
            <>
              <Text tag='h1' variant='h1' offset='half-bottom'>
                Your account is already activated!
              </Text>

              <Button variant='primary' to={SIGNIN_PATH}>Continue</Button>
            </>
          )
          : (
            <>
              <ErrorModal error={error} />
            </>
          )
      )}

      {success &&
        <>
          <Text tag='h1' variant='h1' offset='half-bottom'>
            Thanks for activating your account!
          </Text>

          <Button variant='primary' to={SIGNIN_PATH}>Continue</Button>
        </>
      }
    </Layout>
  )
}

ConfirmScreen.propTypes = {
  history: PropTypes.object,
  mutate: PropTypes.func,
  location: PropTypes.object
}

export default graphql(CONFIRM_EMAIL)(ConfirmScreen)
