import gql from 'graphql-tag'

export const LOCATION_FRAGMENT = gql`
  fragment location on LocationType {
    id
    name
    country {
      code
      name
    }
    administrativeDivision1 {
      code
      name
    }
  }
`

export const COMPANY_MEMBER_FRAGMENT = gql`
  fragment companyMember on CompanyMemberType {
    id
    firstName
    lastName
    websiteUrl
    title
    bio
    # createdAt
    # updatedAt
    # company
  }
`

export const COMPANY_VIDEO_SEGMENT_FRAGMENT = gql`
  fragment companyVideoSegment on CompanyVideoSegmentType {
    id
    binary
    duration
    pitchdeckPage
    pitchdeckDisplayStyle
    # createdAt
    # hlsReadyPresets
    # segmentationQueued
    # failedHlsValidation
  }
`

export const USER_COMPANY_MAIN_FRAGMENT = gql`
  fragment userCompanyMain on UserCompanyType {
    id
    members {
      ...companyMember
    }
    name
    yearFounded
    locations {
      ...location
    }
    website
    description
    givenOverallRating
    customerCategory {
      id
      name
    }
    industries {
      id
      name
    }
    stage {
      id
      name
    }
    regions {
      id
      name
    }
    slidesFile
    highlights
    draft
    isPaidSubscriptionActive
    # problem
    # solution
    # timing
    # marketPotential
    # businessModel
    # competitiveAdvantage
    # revenue
    # activeUsers
    # previousCapital
    # fundingSources {
    #   id
    #   name
    # }
  }
  ${COMPANY_MEMBER_FRAGMENT}
  ${LOCATION_FRAGMENT}
`

export const ERRORS_FOR_FIELDS_FRAGMENT = gql`
  fragment errorsForFields on ErrorType {
    field
    messages
  }
`

export const USER_INVESTOR_PROFILE_FRAGMENT = gql`
  fragment userInvestorProfile on UserInvestorProfileType {
    id
    profileUrl
    title
    name
    website
    locations {
      ...location
    }
    organizationSize {
      id
      name
    }
    accreditedInvestor
    numberOfInvestments {
      id
      name
    }
    customerCategory {
      id
      name
    }
    stages {
      id
      name
    }
    regions {
      id
      name
    }
    # revenue
    # activeUsers
    # previousCapital
    # fundingSources {
    #   id
    #   name
    # }
    industries {
      id
      name
    }
    investorTypes {
      id
      name
    }
  }
  ${LOCATION_FRAGMENT}
`

export const CONNECTED_INVESTOR_FRAGMENT = gql`
  fragment connectedInvestor on ConnectedInvestorType {
    id
    investorProfile {
      name
      locations {
        ...location
      }
      investorTypes {
        id
        name
      }
      industries {
        id
        name
      }
      stages {
        id
        name
      }
      customerCategory {
        id
        name
      }
      organizationSize {
        id
        name
      }
      numberOfInvestments {
        id
        name
      }
      createdBy {
        email
        firstName
        lastName
      }
      contactEmailTemplate {
        subject
        body
        signature
      }
    }
    dateConnected
    seen
    chat {
      id
      unreadMessages
    }
  }
  ${LOCATION_FRAGMENT}
`

export const CONNECTED_COMPANY_FRAGMENT = gql`
  fragment connectedCompany on ConnectedCompanyType {
    id
    company {
      id
      name
      description
      createdBy {
        firstName
        lastName
      }
    }
    dateConnected
    chat {
      id
      unreadMessages
    }
  }
`

export const SAVED_COMPANY_FRAGMENT = gql`
  fragment savedCompany on SavedCompanyType {
    id
    company {
      id
      name
      description
    }
    dateSaved
  }
`

export const CONCATENATED_VIDEO_FRAGMENT = gql`
  fragment concatenatedVideo on CompanyConcatenatedVideoType {
    id
    binary
    duration
    status
    videoIsUpToDate
    # createdAt
    # hlsReadyPresets
    # segmentationQueued
    # failedHlsValidation
    # originalFile
    # createdBy
  }
`

export const TEMPLATE_TOPIC_FRAGMENT = gql`
  fragment template on TemplateTopicType {
    id
    subject
    order
    question
  }
`

export const VIDEO_TOPIC_FRAGMENT = gql`
  fragment videoTopic on CompanyVideoTopicType {
    id
    subject
    order
    video {
      ...companyVideoSegment
    }
    question
    teleprompt
    usingTemplate
    template {
      ...template
      }
    }
  ${ COMPANY_VIDEO_SEGMENT_FRAGMENT }
  ${ TEMPLATE_TOPIC_FRAGMENT }
`

export const COMPANY_FRAGMENT = gql`
  fragment company on CompanyType {
    id
    members {
      ...companyMember
    }
    name
    yearFounded
    locations {
      ...location
    }
    website
    description
    givenOverallRating
    customerCategory {
      id
      name
    }
    industries {
      id
      name
    }
    stage {
      id
      name
    }
    regions {
      id
      name
    }
    slidesFile
    highlights
    draft
    interaction
    publishedAt
    videoTopics {
      ...videoTopic
    }
    concatenatedVideo {
      ...concatenatedVideo
    }
    isPaidSubscriptionActive
    # problem
    # solution
    # timing
    # marketPotential
    # businessModel
    # competitiveAdvantage
    # revenue
    # activeUsers
    # previousCapital
    # fundingSources {
    #   id
    #   name
    # }
  }
  ${LOCATION_FRAGMENT}
  ${COMPANY_MEMBER_FRAGMENT}
  ${VIDEO_TOPIC_FRAGMENT}
  ${CONCATENATED_VIDEO_FRAGMENT}
`

export const COMPANY_TELEPROMPT_FRAGMENT = gql`
  fragment videoTopic on CompanyVideoTopicType {
    id
    teleprompt
  }
`
