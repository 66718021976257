import css from './RemovedFromFeedModal.module.sass'
import Modal from "../Modal"
import Text from "../Text"
import React from "react"
import PropTypes from "prop-types"


const RemovedFromFeedModal = (props) => {

  return (
    <Modal
      buttons={props.buttons}
      visible={props.visible}
    >
      <div className={css.congratsModalContainer}>
        <Text tag='p' variant='h3'>
          Your pitch video has been removed from our investors feed.
        </Text>
        <Text tag='div' variant='standardLarger' className={css.bottom}>
          You can re-submit your pitch by clicking on the submit pitch video button.
        </Text>
      </div>
    </Modal>
  )
}

RemovedFromFeedModal.propTypes = {
  buttons: PropTypes.node,
  visible: PropTypes.bool
}


export default RemovedFromFeedModal
