import css from './Footer.module.sass'

import React from 'react'

import List from './List'
import Link from './Link'
import Text from './Text'
import Button from './Button'

const BASE_URL = 'https://www.pitchtape.com'

const Footer = () => {
  return (
    <footer className={css.container}>
      <nav className={css.columns}>
        <section className={css.column}>
          <Text tag='h1' variant='sectionTitle' offset='single-bottom' color='white'>PITCHTAPE</Text>

          <List gap='10'>
            <List.Item>
              <Link color='white' external href={`${BASE_URL}/faq`}>
                FAQ
              </Link>
            </List.Item>
            <List.Item>
              <Link color='white' external href={`${BASE_URL}/terms-of-service`}>
                Terms Of Service
              </Link>
            </List.Item>
            <List.Item>
              <Link color='white' external href={`${BASE_URL}/privacy-policy`}>
                Privacy Policy
              </Link>
            </List.Item>
            <List.Item>
              <Link color='white' external href={`${BASE_URL}/contact`}>
                Contact
              </Link>
            </List.Item>
            <List.Item>
              <Link color='white' external href={`${BASE_URL}/events`}>
                Events
              </Link>
            </List.Item>
          </List>
        </section>

        <section className={css.column}>
          <Text tag='h1' variant='sectionTitle' offset='single-bottom' color='white'>FOUNDERS</Text>

          <List gap='10'>
            <List.Item>
              <Link color='white' external href={`${BASE_URL}/founders/#founders-how-it-works`}>
                How It Works
              </Link>
            </List.Item>
            <List.Item>
              <Link color='white' external href={`${BASE_URL}/founders/#founders-features`}>
                Features
              </Link>
            </List.Item>
          </List>
        </section>

        <section className={css.column}>
          <Text tag='h1' variant='sectionTitle' offset='single-bottom' color='white'>INVESTORS</Text>

          <List gap='10'>
            <List.Item>
              <Link color='white' external href={`${BASE_URL}/investors/#investors-how-it-works`}>
                How It Works
              </Link>
            </List.Item>
            <List.Item>
              <Link color='white' external href={`${BASE_URL}/investors/#investors-features`}>
                Features
              </Link>
            </List.Item>
          </List>
        </section>
      </nav>

      <section className={css.connect}>
        <div className={css.connectButton}>
          <Button variant='iconFooter' href='mailto:hello@pitchtape.com' icon='email' />
        </div>

        <div className={css.connectButton}>
          <Button
            variant='iconFooter'
            external
            href='https://www.linkedin.com/company/pitchtape'
            icon='linkedin'
          />
        </div>
      </section>

      <div className={css.copyright}>
        <Text color='white'>Copyright © {new Date().getFullYear()} Pitchtape</Text>
      </div>
    </footer>
  )
}

export default Footer
